import { useSelector } from "react-redux"
import { Redirect, /* useParams */ } from "react-router-dom";

export const InitialDashboardRouteDecider = () => {
    const me = useSelector(state => state.me);

    if (!me?.scope) {
        return <div>Loading...</div>
    }

    const permissions = me.scope.split(' ');
    if (permissions.includes('full_access')) {
        return <Redirect to={'/dashboard/advisors' + window.location.search} />
    }

    if (permissions[0] === 'manual_input') {
        permissions[0] = 'manual-input';
    }

    return <Redirect to={`/dashboard/${permissions[0]}${window.location.search}`} />
}