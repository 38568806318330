/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { sysAPI } from "../apis/SysAPI";
import { DashboardTemplate } from "../templates/DashboardTemplate"
import { Button, Icon, Pagination, Container, Table, Input, Dropdown } from 'semantic-ui-react'
import { AddAdvisorModal } from '../components/modal/AddAdvisorModal';
import { TabContainer } from '../components/shared/TabContainer';
import { BetaUsersModal } from "../components/modal/BetaUsersModal";

export const DashboardAdvisorsView = () => {
    const history = useHistory();

    const pageSize = 15;
    const [advisorsList, setAdvisorsList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [searchInput, setSearchInput] = useState('');
    // const [company, setCompany] = useState('');
    const [includeCompanies, setIncludeCompanies] = useState([]);
    const [excludeCompanies, setExcludeCompanies] = useState([]);
    const [license, setlicense] = useState('');
    const [companiesList, setCompaniesList] = useState([]);
    // const [search, setSearch] = useState({activePage: 1, company: '', searchInput: '', license: ''});
    const [search, setSearch] = useState({activePage: 1, includeCompanies: '', excludeCompanies: '', searchInput: '', license: ''});

    const [addAdvisorModalState, setAddAdvisorModalState] = useState(false);
    const [showBetaUsersModal, setShowBetaUsersModal] = useState(false);

    const [sort, setSort] = useState('');
    const [sortType, setSortType] = useState('ASC');
    const [sortChange, setSortChange] = useState(0);

    const licenseList = [
        {text: 'All', value: 'All', key: 0},
        {text: 'Purchased license', value: 'purchasedLicense', key: 1},
        {text: 'On Free Trial', value: 'onFreeTrial', key: 2},
        {text: 'With Current Free Trial', value: 'currentFreeTrial', key: 3},
        {text: 'With Expired Free Trial', value: 'expiredFreeTrial', key: 4}
    ]

    useEffect(() => {
        sysAPI.get(`/companies`).then(response => {
            const { companiesList } = response.data;
            const list = companiesList.map(company => ({ key: company, text: company, value: company }))
            list.unshift({ key: 'all', text: 'All', value: 'All' })
            setCompaniesList(list);
        }).catch(err => {
            console.error(err);
        });
        apiCall();
    }, []);

    useEffect(() => {
        apiCall();
    }, [search]);

    const pageChange = (e, { activePage }) => {
        setSearch({...search, activePage});
    }

    const searchBtn_Click = () => {
        // setSearch({activePage: 1, company, searchInput, license});
        setSearch({activePage: 1, includeCompanies, excludeCompanies, searchInput, license});
    }

    const dateFormatting = (date, hour = true) => {
        return date && moment(date).isValid()
            ? hour
                ? moment(date).format('MM/DD/YYYY HH:mm:ss')
                : moment(date).format('MM/DD/YYYY')
            : 'N/A';
    }

    const apiCall = () => {
        // sysAPI.get(`/advisors?page=${search.activePage}&size=${pageSize}&query=${searchInput}&company=${company}&license=${license}`).then(response => {
        sysAPI.get(`/advisors?page=${search.activePage}&size=${pageSize}&query=${searchInput}&include=${includeCompanies}&exclude=${excludeCompanies}&license=${license}&sort=${sort}&sorttype=${sortType}`).then(response => {
            const { advisorsList, totalPages } = response.data;
            // console.log(totalPages);
            setAdvisorsList(advisorsList);
            setTotalPages(totalPages);
        }).catch(err => {

        });
    }

    const HeaderOptions = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button onClick={() => setShowBetaUsersModal(true)}>Beta Users</Button>
                <Button icon onClick={() => setAddAdvisorModalState(true)}>
                    <Icon name='add' />
                </Button>
            </div>
        )
    }

    const advisorRow_Click = (advisor) => {
        return () => {
            history.push(`/dashboard/advisors/${advisor._id}`);
        }
    }

    const sortColumn = (column) => {
        if (column === sort) {
            setSortType(sortType === 'ASC' ? 'DESC' : 'ASC');
            setSortChange(sortChange + 1)
            return
        }

        setSort(column);
        setSortType('ASC');
        setSortChange(sortChange + 1)
    }

    useEffect(() => {
        sortChange > 0 && apiCall();
    }, [sortChange]);

    return (
        <DashboardTemplate scope='advisors'>
            <TabContainer
                title='Advisors Management'
                subtitle='CRUD of Advisors'
                headerOptions={<HeaderOptions />}
            >
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr auto',
                        gridTemplateRows: 'auto auto',
                        gap: '12px 12px',
                    }}
                >
                    <Input
                        value={searchInput}
                        onChange={e => setSearchInput(e.currentTarget.value)}
                        icon='search'
                        iconPosition='left'
                        style={{ gridArea: '1 / 1 / 2 / 2' }}
                        onKeyPress={e => (e.charCode === 13 || e.keyCode === 13 || e.which === 13) && searchBtn_Click()}
                    />
                    <Dropdown style={{ gridArea: '2 / 1 / 3 / 2' }} placeholder='Include Companies' multiple selection search options={companiesList} value={includeCompanies} onChange={(e, { value }) => {setIncludeCompanies(value)}} />
                    <Dropdown style={{ gridArea: '2 / 2 / 3 / 3' }} placeholder='Exclude Companies' multiple selection search options={companiesList} value={excludeCompanies} onChange={(e, { value }) => {setExcludeCompanies(value)}} />
                    <Dropdown style={{ gridArea: '1 / 2 / 2 / 3' }} placeholder='License Type' selection search options={licenseList} value={license} onChange={(e, { value }) => {setlicense(value)}} />
                    <Button style={{ gridArea: '1 / 3 / 3 / 4' }} onClick={searchBtn_Click}>Filter</Button>
                </div>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Full Name</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell
                                className={`table-header__order ${sort === 'company' ? 'active' : ''}`}
                                onClick={() => sortColumn('company')}
                            >
                                Company
                                {(sort === 'company' && sortType === 'ASC') && <Icon name="sort up" />}
                                {(sort === 'company' && sortType === 'DESC') && <Icon name="sort down" />}
                                {sort !== 'company' && <Icon name="sort" />}
                            </Table.HeaderCell>
                            <Table.HeaderCell>POC</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>2FA</Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign="right"
                                className={`table-header__order ${sort === 'expires' ? 'active' : ''}`}
                                onClick={() => sortColumn('expires')}
                            >
                                License Expires
                                {(sort === 'expires' && sortType === 'ASC') && <Icon name="sort up" />}
                                {(sort === 'expires' && sortType === 'DESC') && <Icon name="sort down" />}
                                {sort !== 'expires' && <Icon name="sort" />}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign="right"
                                className={`table-header__order ${sort === 'renewal' ? 'active' : ''}`}
                                onClick={() => sortColumn('renewal')}
                            >
                                Renewal Date
                                {(sort === 'renewal' && sortType === 'ASC') && <Icon name="sort up" />}
                                {(sort === 'renewal' && sortType === 'DESC') && <Icon name="sort down" />}
                                {sort !== 'renewal' && <Icon name="sort" />}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign="right"
                                className={`table-header__order ${sort === 'lastLogin' ? 'active' : ''}`}
                                onClick={() => sortColumn('lastLogin')}
                            >
                                Last Login
                                {(sort === 'lastLogin' && sortType === 'ASC') && <Icon name="sort up" />}
                                {(sort === 'lastLogin' && sortType === 'DESC') && <Icon name="sort down" />}
                                {sort !== 'lastLogin' && <Icon name="sort" />}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="right"># Logins</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {advisorsList?.map(advisor => (
                            <Table.Row key={advisor._id} style={{ color: advisor.hasActiveSession ? 'green' : 'black' }}>
                                <Table.Cell>
                                    <span style={{ cursor: 'pointer' }} onClick={advisorRow_Click(advisor)}>{advisor.fullName}</span>
                                </Table.Cell>
                                <Table.Cell>{advisor.email}</Table.Cell>
                                <Table.Cell>{advisor.nameCompany}</Table.Cell>
                                <Table.Cell>{advisor.poc}</Table.Cell>
                                <Table.Cell>{advisor.status}</Table.Cell>
                                <Table.Cell positive={advisor.hasEnabled2FA}>{advisor.hasEnabled2FA ? 'Active' : 'Inactive'}</Table.Cell>
                                <Table.Cell textAlign="right">{dateFormatting(advisor.licenseExpiresIn)}</Table.Cell>
                                <Table.Cell textAlign="right">{dateFormatting(advisor.renewalDate, false)}</Table.Cell>
                                <Table.Cell textAlign="right">{dateFormatting(advisor.activity?.lastLogin || advisor.lastLogin)}</Table.Cell>
                                <Table.Cell textAlign="right">{advisor.howManyLogins !== 'N/A' ? advisor.howManyLogins : '0'}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <Container fluid textAlign='right'>
                    <Pagination totalPages={totalPages} activePage={search.activePage} onPageChange={pageChange} />
                </Container>
            </TabContainer>
            <AddAdvisorModal
                modalState={addAdvisorModalState}
                setModalState={value => setAddAdvisorModalState(value)}
            />
            <BetaUsersModal open={showBetaUsersModal} onClose={() => setShowBetaUsersModal(false)} />
        </DashboardTemplate>
    )
}