import { useEffect, useState } from "react";
import moment from 'moment';
import { sysAPI } from "../apis/SysAPI";
import { DashboardTemplate } from "../templates/DashboardTemplate"
import { Button, Pagination, Container, Table, Dropdown, Input } from 'semantic-ui-react'
import { TabContainer } from '../components/shared/TabContainer';
import { fpalphaAPI } from "../apis/FPAlphaAPI";

export const DashboardClientsView = () => {
    const pageSize = 15;
    const [clientsList, setClientsList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [company, setCompany] = useState('');
    const [companiesList, setCompaniesList] = useState([]);
    const [search, setSearch] = useState({activePage: 1, company: '', searchInput: ''});
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        apiCall();

        sysAPI.get(`/companies`).then(response => {
            const { companiesList } = response.data;
            const list = companiesList.map(company => ({ key: company, text: company, value: company }))
            list.unshift({ key: 'all', text: 'All', value: 'All' })
            setCompaniesList(list);
        }).catch(err => {
            console.error(err);
        });

    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        apiCall();

    // eslint-disable-next-line
    }, [search]);

    const pageChange = (e, { activePage }) => {
        setSearch({...search, activePage});
    }

    const searchBtn_Click = () => {
        setSearch({activePage: 1, company, searchInput});   
    }

    const apiCall = () => {
        sysAPI.get(`/clients?page=${search.activePage}&perPage=${pageSize}&companyName=${company}&search=${searchInput}`).then(response => {
            const { clientsList, metadata } = response.data;
            setClientsList(clientsList);
            setTotalPages(metadata[0].pages);
        }).catch(err => {

        });
    }

    const dateFormatting = (date) => {
        return date && moment(date).isValid()
            ? moment(date).format('MM/DD/YYYY HH:mm:ss')
            : 'N/A';
    }

    const downloadBtn_Click = (clientId, clientEmail) => {
        return () => {
            sysAPI.post('/generate-code').then(response => {
                fpalphaAPI.post('/tools/exportClient2JSON', {
                    clientsIds: [clientId],
                    download: true,
                    code: response.data.code,
                }, {
                    responseType: 'blob',
                }).then(response => {
                    const href = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    link.setAttribute('download', `${clientEmail}.fpclient`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            });
        }
    };

    return (
        <DashboardTemplate scope='clients'>
            <TabContainer
                title='Clients Management'
                subtitle='Clients'
                icon='address card'
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Input
                        value={searchInput}
                        onChange={e => setSearchInput(e.currentTarget.value)}
                        icon='search'
                        iconPosition='left'
                        style={{ marginRight: '10px', width: '300px' }}
                        onKeyPress={e => (e.charCode === 13 || e.keyCode === 13 || e.which === 13) && searchBtn_Click()}
                    />
                    <Dropdown style={{ margin: '0 6px 0 0' }} placeholder='Company' selection search options={companiesList} value={company} onChange={(e, { value }) => {setCompany(value)}} />
                    <Button onClick={searchBtn_Click}>Filter</Button>
                </div>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Last Name</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell>Company</Table.HeaderCell>
                            <Table.HeaderCell>Owner</Table.HeaderCell>
                            <Table.HeaderCell>Shared</Table.HeaderCell>
                            <Table.HeaderCell>Creation Date</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {clientsList?.map(client => (
                            <Table.Row key={client._id} style={{ color: client.isProspect ? 'blue' : 'black' }}>
                                <Table.Cell>{client.clientIDFirstName}</Table.Cell>
                                <Table.Cell>{client.clientIDLastName}</Table.Cell>
                                <Table.Cell>{client.email}</Table.Cell>
                                <Table.Cell>{client.companyName}</Table.Cell>
                                <Table.Cell>{client.ownerData.fullName}</Table.Cell>
                                <Table.Cell>{client.idAdvisorList?.length > 1 ? 'Yes' : 'No'}</Table.Cell>
                                <Table.Cell>{dateFormatting(client.creationDate)}</Table.Cell>
                                <Table.Cell><Button onClick={downloadBtn_Click(client._id, client.email)}>Download</Button></Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <Container fluid textAlign='right'>
                    <Pagination totalPages={totalPages} activePage={search.activePage} onPageChange={pageChange} />
                </Container>
            </TabContainer>
        </DashboardTemplate>
    )
}