import fulltax_2019 from './fulltax_2019';
import fulltax_2020 from './fulltax_2020';
import fulltax_2021 from './fulltax_2021';
import fulltax_2022 from './fulltax_2022';
import auto from './auto';
import home from './home'

export const manualInputFields = {
    fulltax: {
        '2019': fulltax_2019,
        '2020': fulltax_2020,
        '2021': fulltax_2021,
        '2022': fulltax_2022,
    },
    auto:auto,
    home:home,
};