import { useEffect, useState } from 'react'
//import { useSelector } from "react-redux";
import { useParams } from 'react-router'
import { Dropdown, Button, Loader, Dimmer, Message } from 'semantic-ui-react'
import { sysAPI } from '../apis/SysAPI'
import { SetOnHoldModal } from '../components/modal/SetOnHoldModal';

export const ClientEstateFilesViews = () => {
    const params = useParams();
    const [documentSelected, setDocumentSelected] = useState(null);
    // eslint-disable-next-line
    const [documentSelectedType, setDocumentSelectedType] = useState(null);
    const [iframeSrc, setIframeSrc] = useState(null);
    const [docs, setDocs] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [documentTypeSelected, setDocumentTypeSelected] = useState(null);
    const [formLoader, setFormLoader] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [formError, setFormError] = useState(false);
    const [delegateManualInput, setDelegateManualInput] = useState(false);
    const [reviewerGroup, setReviewerGroup] = useState([]);
    const [reviewerSelected, setReviewerSelected] = useState('');
    const [onHoldModal, setOnHoldModal] = useState(false);
    const [successHold, setSuccessHold] = useState(false);

    const { clientId, assetIndex } = params;

    const docTypes = [
        {
            text: 'Will',
            value: 'Will',
        },
        {
            text: 'Revocable trust',
            value: 'Revocable trust',
        },
        {
            text: 'Irrevocable trust',
            value: 'Irrevocable trust',
        },
        {
            text: 'POA',
            value: 'POA',
        },
        {
            text: 'Life insurance',
            value: 'Life insurance',
        },
        {
            text: 'Medical directives',
            value: 'Medical directives',
        },
        {
            text: 'Amendments',
            value: 'Amendments',
        },
    ];

    useEffect(() => {
        const url = assetIndex === 'home' || assetIndex === 'auto'
            ? `/insurance-snapshot/file/list?clientId=${clientId}&area=${assetIndex}`
            : `/estate-manual-input/file/list?clientId=${clientId}&index=${assetIndex}`;

        sysAPI.get(url).then(response => {
            setDocs(response.data.map(doc => ({
                key: doc.path,
                text: doc.name,
                value: doc.path
            })));
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleShowDocument = () => {
        setShowLoader(true);
        const { clientId, assetIndex } = params;
        sysAPI.get(`/download/files?path=${documentSelected}`, {
            responseType: 'blob',
        }).then(response => {
            let blob = new Blob([response.data], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(blob);
            setIframeSrc(url);
            setShowLoader(false);
            console.log('Document:', documentSelected);
            sysAPI.get(`/estate-manual-input/documentTypes?clientId=${clientId}&index=${assetIndex}&documentId=${documentSelected}`)
            .then(response => {
                console.log(response.data.document)
                setDocumentSelectedType(null);
                setFormSuccess(false);
                setShowForm(!response.data.document ? true : false);
            });
        }).catch(err => {
            console.error(err);
        });
    }

    const closeTabBtn_Click = () => {
        window.opener = null;
        window.open('', '_self');
        window.close();
    }

    const handleSaveDocumentType = async (e) => {
        const { clientId, assetIndex } = params;
        e.preventDefault();

        if (!documentTypeSelected) {
            setFormError(true);
            return false;
        }

        setFormLoader(true);
        sysAPI.post('/estate-manual-input/documentTypes', {
            clientId,
            index: assetIndex,
            documentId: documentSelected,
            documentType: documentTypeSelected,
        }).then(() => {
            setFormLoader(false);
            setFormSuccess(true);
            setShowForm(false);
            setDocumentTypeSelected(null);
        }).catch((err) => {
            setFormError(err);
        });
    }

    const delegateManualInput_Click = () => {
        const { clientId, assetIndex } = params;
        sysAPI.get(`/estate-manual-input/reviewer-groups`,{
            params:{ 
                clientId,
                index: assetIndex,
            }
        }).then(response => {
            setReviewerGroup(response.data.groups
                .map(reviewer => ({
                    key: reviewer,
                    text: reviewer,
                    value: reviewer.toLowerCase()
                })));
            setDelegateManualInput(true)
        });
    }

    const delegateManualInputSave_Click = () => {
        const { clientId, assetIndex } = params;
        sysAPI.post('/estate-manual-input/change-advisor-group', {
            clientId,
            index: assetIndex,
            reviewerGroup: reviewerSelected
        }).then(() => {
            closeTabBtn_Click()
        }).catch((err) => {
            alert('There has been and error delegating the manual input.')
        });
    }

    const sendToOnHold = () => {
        setOnHoldModal(true);
    }

    useEffect(() => {
        if (successHold) {
            setTimeout(() => {
                setSuccessHold(false);
            }, 5000);
        }
    }, [successHold]);

    return (
    <div style={{ width: '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column', position: 'relative' }}>
        <div style={{ margin: '10px 20px', display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <Dropdown
                    placeholder='Select document…'
                    options={docs}
                    selection
                    onChange={(e, data) => { setDocumentSelected(data.value)}}
                    style={{ width: '400px' }}
                />
                <Button color='blue' style={{ marginLeft: '1.5rem' }} onClick={handleShowDocument}>View selected document</Button>
            </div>
            <div style={{display:'flex'}}>
                {assetIndex !== 'home' && assetIndex !== 'auto' &&
                <>
                    <Button color='orange' onClick={sendToOnHold} style={{marginRight: '10px'}}>Move to On Hold</Button>
                    <div style={{marginRight:'10px'}}>
                        {delegateManualInput && 
                        <>
                            <Dropdown
                                placeholder='Select document…'
                                options={reviewerGroup}
                                selection
                                onChange={(e, data) => { setReviewerSelected(data.value)}}
                                style={{ width: '200px', marginRight: '5px' }}
                            />
                            <Button color='green' onClick={delegateManualInputSave_Click}>Save</Button>
                            <Button color='red' onClick={() => setDelegateManualInput(false)}>Cancel</Button>
                        </>}
                        {!delegateManualInput && 
                            <Button style={{ width: '300px' }} color='green' onClick={delegateManualInput_Click}>Delegate Manual Input</Button>
                        }
                    </div>
                </>}
                <Button color='grey' onClick={closeTabBtn_Click}>Close Tab</Button>
            </div>
        </div>
        {successHold && <Message
            success
            header='Success!'
            content={successHold}
        />}
        <iframe src={iframeSrc} frameBorder='0' title='Document' style={{ width: '100%', flex: '1 1 auto' }} />
        <Dimmer active={showLoader}>
            <Loader />
        </Dimmer>

        <div
            style={{
                position: 'absolute',
                bottom: '24px',
                right: !isNaN(assetIndex) && showForm ? '24px' : '-400px',
                width: '400px',
                backgroundColor: '#fbbd08',
                padding: '24px',
                borderRadius: '10px',
                transition: 'all 0.25s linear',
            }}
        >
            <h2>Document type not identified.</h2>
            <p>Please input which type of document is being displayed:</p>

            <form onSubmit={handleSaveDocumentType}>
                <div style={{ marginBottom: '16px' }}>
                    <Dropdown
                        placeholder='Select document…'
                        options={docTypes}
                        selection
                        value={documentTypeSelected}
                        onChange={(e, data) => setDocumentTypeSelected(data.value)}
                        style={{ width: '100%' }}
                        required
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <p
                        style={{
                            fontSize: '12px',
                            lineHeight: 1,
                            margin: 0,
                            padding: 0,
                        }}
                    >
                        {formError ? <span style={{color: 'red'}}><i aria-hidden="true" className="exclamation icon"></i>{formError.message || 'Please select a Document Type'}</span> : ''}
                        {formSuccess ? <span style={{color: 'green'}}><i aria-hidden="true" className="check icon"></i>Document Type Saved!</span> : ''}
                    </p>
                    <button
                        className={`${formLoader ? 'ui loading' : 'ui'} primary button`}
                        disabled={formSuccess || formLoader ? true : false}
                    >
                        { formSuccess ? 'Saved' : 'Submit' }
                    </button>
                </div>
            </form>

        </div>
        {onHoldModal &&
            <SetOnHoldModal
                setOnHoldModal={setOnHoldModal}
                onHoldModal={onHoldModal}
                setSuccessMessage={setSuccessHold}
            />}
    </div>
    )
}
