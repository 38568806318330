import { usStates } from '../../data/usStates';
import { InputItem } from '../shared/EstateInputItem';

export const General = ({ docId }) => {

    const statesOptions = [
        { key: '', value: '', text: '' },
        ...Object.keys(usStates).map(state => ({
            key: usStates[state],
            value: state,
            text: usStates[state],
        })),
    ];
    return (
        <>
            <InputItem 
                    docType={docId}
                    label={`State`}
                    field={`stateOfResidence`}
                    nOptions={statesOptions}
                    version={[1,2,3]}
            />

            <InputItem docType={docId} label='Additional Information' field='additionalInformation' multiline version={[1,2,3]}/>

            <div style={{ marginBottom: '20px' }}>
                <h4>Will information</h4>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the client have a will?' field='generalQuestion___clientIDHasWill' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Was the will created prior to 2010?' field='generalQuestion___clientIDsearchWillCreationDatePrior2010' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the executor have the power to enter into business agreements?' field='generalQuestion___clientIDbusinessInWill' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is there a residuary clause in the will?' field='generalQuestion___clientIDresiduaryClauseInWill' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Are there contingent beneficiaries in the will?' field='generalQuestion___clientIDcontingentBeneficiaryInWill' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the will create a trust that provides for distributions under a HEMS standard?' field='generalQuestion___clientIDuseOfHEMSProvisions' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Are capital gains allowed to be distributed for income tax reduction in the will?' field='generalQuestion___clientIDCapitalGainsDistributionWill' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is there a testamentary trust?' field='generalQuestion___clientIDtestamentaryTrustInWill' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the client have a disclaimer or bypass trust?' field='generalQuestion___clientIDDisclaimerOrBypassTrustInWill' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is a couple named as guardians?' field='generalQuestion___clientIDguardiansInWill' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Are there personal and property guardians in the will?' field='generalQuestion___clientIDsearchPPG' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is there a minor’s clause in the will?' field='generalQuestion___clientIDsearchMultipleTrusts' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Are there gift conditions left in the will?' field='generalQuestion___clientIDgiftConditionsInWill' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is there a trust for minor children in the will?' field='generalQuestion___clientIDsearchPersonalGuardian' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is there a shared gift in the will?' field='generalQuestion___clientIDsharedGiftInWill' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is there a disclaimer trust?' field='generalQuestion___clientIDsearchDisclaimerProvision' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Are any charities receiving a legacy in the will?' field='generalQuestion___clientIDsearchCharity' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the will contain a successor executor(s)?' field='generalQuestion___clientIDreplacementExecutor' version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is there a property memorandum or precatory list in the will?' field='generalQuestion___clientIDclientHasPropertyMemorandum' version={[1,2,3]}/>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Revocable trust information</h4>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the client have a revocable trust?' field='generalQuestion___clientIDHasRevocableTrust' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Was the revocable trust created prior to 2010?' field='generalQuestion___clientIDsearchRevocableTrustCreationDatePrior2010' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the revocable trust contain a power of appointment?' field='generalQuestion___clientIDpowerOfAppointment' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the revocable trust contain a successor trustee(s)?' field='generalQuestion___clientIDsuccessorTrusteeR' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is the payment of taxes addressed in the trust?' field='generalQuestion___clientIDsearchTrustInstrumentRequiresTrusteeToPayExecutor' ignorBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Are there contingent beneficiaries in the revocable trust?' field='generalQuestion___clientIDcontingentBeneficiaryInRevocableTrust' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is there a testamentary trust?' field='generalQuestion___clientIDtestamentaryTrustInRevocableTrust' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Are capital gains allowed to be distributed for income tax reduction in the revocable trust?' field='generalQuestion___clientIDCapitalGainsDistributionRevTrust' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the revocable trust create a trust that provides for distributions under a HEMS standard?' field='generalQuestion___clientIDuseOfHEMSProvisionsRevocableTrust' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the client have a disclaimer or bypass trust on revocable trust?' field='generalQuestion___clientIDDisclaimerOrBypassTrustInRevocableTrust' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is there a shared gift in the revocable trust?' field='generalQuestion___clientIDsharedGiftInRevocableTrust' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='s there a minor’s clause in the revocable trust?' field='generalQuestion___clientIDsearchMultipleTrustsRevocableTrust' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is there a disclaimer trust in the revocable trust?' field='generalQuestion___clientIDsearchDisclaimerProvisionRevocableTrust' version={[1,2,3]}/>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Personal information</h4>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the client have advanced medical directives?' field='generalQuestion___clientIDsearchMedicalCare' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the client have a retirement account?' field='generalQuestion___clientIDClientHasRetirementAccount' version={[1,2,3]}/>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Irrevocable trust information</h4>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the client have an irrevocable trust?' field='generalQuestion___clientIDHasIrrevocableTrust' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the client have a QPRT?' field='generalQuestion___clientIDqprt' version={[1,2,3]}/>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Life insurance information</h4>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the client have life insurance?' field='generalQuestion___clientIDHasLifeInsurancePolicy' version={[1,2,3]}/>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Choosing Between Will and Revocable Trust</h4>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is the client excluding children from his/her revocable trust and/or will?' field='generalQuestion___clientIDExcludingChildrenFromTrustOrWill' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is the client excluding a spouse from his/her revocable trust and/or will?' field='generalQuestion___clientIDExcludingSpouseFromTrustOrWill' version={[1,2,3]}/>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Evaluating Revocable Trusts</h4>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='If the client has a revocable living trust, are fiduciaries for the will and the revocable living trust the same?' field='generalQuestion___clientIDRevocableLivingTrust' version={[1,2,3]}/>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Evaluating Whether to Use Irrevocable Trusts</h4>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the client have a blended family?' field='generalQuestion___clientIDBlendedFamily' version={[1,2,3]}/>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Evaluating powers of attorney</h4>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the client have a Power of Attorney (POA)' field='generalQuestion___clientIDHasPOA' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Has elder abuse been addressed in Power of Attorney?' field='generalQuestion___clientIDadressedElderAbuse' version={[1,2,3]}/>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Planning for Minor Children</h4>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the client have stepchildren?' field='generalQuestion___clientIDHasStepchildren' version={[1,2,3]}/>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Complex Estate Planning</h4>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the client own a personal residence?' field='generalQuestion___clientIDPersonalResidence' ignoreBorder version={[1,2,3]}/>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Is the client the grantor or beneficiary of an irrevocable trust?' field='generalQuestion___clientIDGrantorBeneficiary' version={[1,2,3]}/>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Miscellaneous</h4>
                <InputItem componentWidth={'small'} options={['Yes', 'No']} withoutPages docType={docId} label='Does the client want to provide for a pet?' field='generalQuestion___clientIDWantsToProvideForPet' version={[1,2,3]}/>
            </div>
        </>
    )
}
