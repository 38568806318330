export const estateManualInputReducer = (estateManualInput = {}, action) => {
    console.log('action.type', action.type)
    switch (action.type) {
        case 'set_estate_manual_input_field': {
            const { docType, field, value } = action.payload;
            const auxEstateManualInput = { ...estateManualInput };

            if (!(docType in auxEstateManualInput)) {
                auxEstateManualInput[docType] = {};
            }

            auxEstateManualInput[docType][field] = value;
            return auxEstateManualInput;
        }
        case 'remove_estate_manual_input_field': {
            const { docType, field } = action.payload;
            const auxEstateManualInput = { ...estateManualInput };
            delete auxEstateManualInput?.[docType]?.[field];
            return auxEstateManualInput;
        }
        case 'reset_estate_manual_input_fields': {
            return {};
        }
        case 'add_estate_manual_input_document': {
            if (!estateManualInput[action.payload.id]) {
                const auxEstateManualInput = { ...estateManualInput };
                auxEstateManualInput[action.payload.id] = {};
                return auxEstateManualInput;
            }
            return estateManualInput;
        }
        case 'remove_estate_manual_input_document': {
            const auxEstateManualInput = { ...estateManualInput };
            auxEstateManualInput[action.payload.id] = undefined;
            return auxEstateManualInput;
        }
        default: {
            return estateManualInput;
        }
    }
}

export const estateManualInputAssetsReducer = (assets = {}, action) => {
    switch (action.type) {
        case 'add_estate_manual_input_asset': {
            const { docType, id } = action.payload;
            const auxAssets = { ...assets };
            
            if (!(docType in assets)) {
                auxAssets[docType] = [];
            }

            auxAssets[docType].push({ id });
            return auxAssets;
        }
        case 'remove_estate_manual_input_asset': {
            const { docType, id } = action.payload;
            const auxAssets = { ...assets };

            const docAssets = auxAssets?.[docType];
            const toDeleteAsset = docAssets.find(asset => asset.id === id);
            if (toDeleteAsset) {
                const index = docAssets.indexOf(toDeleteAsset);
                docAssets.splice(index, 1);
            }

            return auxAssets;
        }
        case 'reset_estate_manual_input_assets': {
            return {};
        }
        default: {
            return assets;
        }
    }
};

export const estateManualInputRequestReducer = (estateManualInputRequest = {}, action) => {
    switch (action.type) {
        case 'set_estate_manual_input_request': {
            return action.payload;
        }
        default: {
            return estateManualInputRequest;
        }
    }
};

export const estateManualInputDocumentsReducer = (estateManualInputDocuments = {}, action) => {
    switch (action.type) {
        case 'add_estate_manual_input_document':
            return { ...estateManualInputDocuments, [action.payload.id]: { type: action.payload.docType } };
        case 'remove_estate_manual_input_document':
            const auxDocs = { ...estateManualInputDocuments };
            delete auxDocs[action.payload.id];
            return auxDocs;
        case 'reset_estate_manual_input_documents':
            return {};
        default:
            return estateManualInputDocuments;
    }
};

export const estateManualInputChangeReducer = (estateManualInputChangeLog = {}, action) => {
    switch (action.type) {
        case 'set_estate_manual_input_change_field': {
            const { docType, field, value } = action.payload;
            const auxEstateManualInput = { ...estateManualInputChangeLog };

            if (!(docType in auxEstateManualInput)) {
                auxEstateManualInput[docType] = {};
            }

            auxEstateManualInput[docType][field] = value;
            return auxEstateManualInput;
        }
        case 'reset_estate_manual_input_change_fields': {
            return {};
        }
        default: {
            return estateManualInputChangeLog;
        }
    }
}

export const estateManualInputChangeAssetsReducer = (assetsChangeLog = {}, action) => {
    switch (action.type) {
        case 'add_estate_manual_input_asset': {
            const { docType, id } = action.payload;
            const auxAssets = { ...assetsChangeLog };
            
            if (!(docType in assetsChangeLog)) {
                auxAssets[docType] = [];
            }

            auxAssets[docType].push({ id });
            return auxAssets;
        }
        case 'reset_estate_manual_input_assets': {
            return {};
        }
        default: {
            return assetsChangeLog;
        }
    }
};

export const estateManualInputChangeDocumentsReducer = (estateManualInputChangeDocuments = {}, action) => {
    switch (action.type) {
        case 'add_estate_manual_input_change_document':
            return { ...estateManualInputChangeDocuments, [action.payload.field]: { type: action.payload.docType } };
        default:
            return estateManualInputChangeDocuments;
    }
};