import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Message } from "semantic-ui-react";

export const ComponentTemplate = ({ children, scope }) => {
    const me = useSelector(state => state.me);

    useEffect(() => {

    }, []);

    if (!me) {
        return <div>Loading...</div>
    }

    if (!me.scope.split(' ').some(s => ['full_access', ...scope.split(' ')].includes(s))) {
        return <Message color='red'>
            Insufficient permissions. Please contact support for granting access.
        </Message>
    }

    return (
        <div className="component-template">
            {children}
        </div>
    )
}