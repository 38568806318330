import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import { LoginView } from "../views/LoginView"
import { LogoutView } from "../views/LogoutView"
import { DashboardRouter } from "./DashboardRouter"

export const RootRouter = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/' render={() => <Redirect to='/dashboard' />} />
                <Route exact path='/login' component={LoginView} />
                <Route exact path='/logout' component={LogoutView} />
                <Route path='/dashboard' component={DashboardRouter} />
            </Switch>
        </BrowserRouter>
    )
}