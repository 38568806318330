import { useEffect, /* useRef, */ useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentCompany, } from "../../actions/company.actions";
import { useParams } from "react-router-dom";
import { DashboardTemplate } from "../../templates/DashboardTemplate"
import { /* Button, Icon, */ Header, Segment, /* Input */ } from 'semantic-ui-react'
import CompanyMenu from "../../components/menu/CompanyMenu";
import CompanyInformationTab from "../../components/tabs/company/InformationTab";
import CompanyAdvisorsTab from "../../components/tabs/company/AdvisorsTab";
import CompanyEstateSnapshotTab from "../../components/tabs/company/EstateSnapshotTab";

const CompanyProfile = () => {
    const params = useParams();
    const [activeTab, setActiveTab] = useState('information');
    const dispatch = useDispatch();
    const companyProfile = useSelector(state => state.currentCompany);
    useEffect(() => {
        const { companyId } = params;
        //console.log({companyId})
        dispatch(fetchCurrentCompany(companyId));

    // eslint-disable-next-line
    }, []);

    return (
         <DashboardTemplate scope='companies'>
          <div>
            <Header as='h2'>
              <div style={{display: "flex", gap: "10px" }}>
                <div>
                  <img alt={companyProfile?.name} src={companyProfile?.logo} style={{width: "150px", height:"auto"}} />
                </div>
                <div>
                  <Header.Content>
                      {companyProfile?.name}
                      <Header.Subheader>
                        <div>
                          <strong>Advisors: </strong> {companyProfile?.advisorList?.length}
                        </div>
                        <div>
                          <strong>POC: </strong> {companyProfile?.poc?.fullName}
                        </div>
                      </Header.Subheader>
                  </Header.Content>
                </div>

              </div>
            </Header>

            <CompanyMenu activeTab={activeTab} setActiveTab={setActiveTab} />
            <Segment attached='bottom'>
                <CompanyInformationTab activeTab={activeTab} />
                <CompanyAdvisorsTab activeTab={activeTab} />
                <CompanyEstateSnapshotTab activeTab={activeTab} />
            </Segment>
            {/* {JSON.stringify(companyProfile)} */}
          </div>
        </DashboardTemplate>
    )
}

export default CompanyProfile