// eslint-disable-next-line
export default [
    {
        section: 'Form 1040',
        fields: [
            {
                name: 'wages',
                text: 'Wages',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'taxExemptInterest',
                text: 'Tax-exempt interest',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'taxInterest',
                text: 'Taxable interest',
                line: 'Line 2b',
                type: 'number',
            },
            {
                name: 'qualifiedDividends',
                text: 'Qualified dividends',
                line: 'Line 3a',
                type: 'number',
            },
            {
                name: 'totalDividends',
                text: 'Ordinary dividends',
                line: 'Line 3b',
                type: 'number',
            },
            {
                name: 'iraDistribution',
                text: 'IRA distributions',
                line: 'Line 4a',
                type: 'number',
            },
            {
                name: 'taxableIRA',
                text: 'IRA distributions taxable amount',
                line: 'Line 4b',
                type: 'number',
            },
            {
                name: 'pension',
                text: 'Pensions and annuities',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'taxablePension',
                text: 'Pensions and annuities taxable amount',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'socialSecurityBenefits',
                text: 'Social security benefits',
                line: 'Line 6a',
                type: 'number',
            },
            {
                name: 'socialSecurity',
                text: 'Social security benefits taxable amount',
                line: 'Line 6b',
                type: 'number',
            },
            {
                name: 'capitalGainOrLoss',
                text: 'Capital gain or (loss)',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'otherIncome',
                text: 'Other Income',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'totalIncome',
                text: 'Total income',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'adjustedIncome',
                text: 'Adjustments to income',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'adjustedGrossIncome',
                text: 'Adjusted gross income',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'standardDeductions',
                text: 'Standard deductions or itemized deductions',
                line: 'Line 12a',
                type: 'number',
            },
            {
                name: 'charitableContributions1040',
                text: 'charitable Contributions 1040',
                line: 'Line 12b',
                type: 'number',
            },
            {
                name: 'sumStandardAndCharitable',
                text: 'sum Standard and Charitable',
                line: 'Line 12c',
                type: 'number',
            },
            {
                name: 'qbIncome',
                text: 'Qualified business income deductions',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'sumDeductions',
                text: 'sum Deductions',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'taxableIncome',
                text: 'Taxable income',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'taxes',
                text: 'Taxes',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'line17_1040',
                text: 'line 17',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'line18_1040',
                text: 'line 18',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'qualifyingDependentCreditClaimed',
                text: 'line 19',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'line20_1040',
                text: 'line 20',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'line21_1040',
                text: 'line 21',
                line: 'Line 21',
                type: 'number',
            },
            {
                name: 'line22_1040',
                text: 'line 22',
                line: 'Line 22',
                type: 'number',
            },
            {
                name: 'otherTaxesWithSelfEmployment',
                text: 'line 23',
                line: 'Line 23',
                type: 'number',
            },
            {
                name: 'totalTax',
                text: 'Total tax',
                line: 'Line 24',
                type: 'number',
            },
            {
                name: 'federalIncomeTaxWithheld',
                text: 'line 25d',
                line: 'Line 25d',
                type: 'number',
            },
            {
                name: 'estimatedTaxPaymentsAmountForLastYear',
                text: 'line 26',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'earnedIncomeCredit',
                text: 'line 27',
                line: 'Line 27',
                type: 'number',
            },
            {
                name: 'additionalChildTaxCredit',
                text: 'line 28',
                line: 'Line 28',
                type: 'number',
            },
            {
                name: 'americanOpportunityCreditTaken',
                text: 'line 29',
                line: 'Line 29',
                type: 'number',
            },
            {
                name: 'recoveryRebateCredit',
                text: 'line 30',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'amountfromSchedule3line15',
                text: 'line 31',
                line: 'Line 31',
                type: 'number',
            },
            {
                name: 'totalOtherPayments',
                text: 'line 32',
                line: 'Line 32',
                type: 'number',
            },
            {
                name: 'totalPayments',
                text: 'line 33',
                line: 'Line 33',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 1',
        fields: [
            {
                name: 'clientHasSchedule1',
                text: 'Has Schedule 1?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name:'taxableRefunds',
                text: 'Taxable refunds',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'alimonyReceived',
                text: 'Alimony received',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'businessIncome',
                text: 'Business income/loss',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'otherGainsOrLosses',
                text: 'Other gains or losses',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'rentalRealEstate',
                text: 'Rental real estate, royalties, partnerships, S corporations, trusts, etc',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'farmIncome',
                text: 'Farm income',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'unemployementCompensation',
                text: 'Unemployed compensation',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'otherIncome8a',
                text: 'otherIncome8a',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'otherIncome8b',
                text: 'otherIncome8b',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'otherIncome8c',
                text: 'otherIncome8c',
                line: 'Line 8c',
                type: 'number',
            },
            {
                name: 'otherIncome8d',
                text: 'otherIncome8d',
                line: 'Line 8d',
                type: 'number',
            },
            {
                name: 'otherIncome8e',
                text: 'otherIncome8e',
                line: 'Line 8e',
                type: 'number',
            },
            {
                name: 'otherIncome8f',
                text: 'otherIncome8f',
                line: 'Line 8f',
                type: 'number',
            },
            {
                name: 'otherIncome8g',
                text: 'otherIncome8g',
                line: 'Line 8g',
                type: 'number',
            },
            {
                name: 'otherIncome8h',
                text: 'otherIncome8h',
                line: 'Line 8h',
                type: 'number',
            },
            {
                name: 'otherIncome8i',
                text: 'otherIncome8i',
                line: 'Line 8i',
                type: 'number',
            },
            {
                name: 'otherIncome8j',
                text: 'otherIncome8j',
                line: 'Line 8j',
                type: 'number',
            },
            {
                name: 'otherIncome8k',
                text: 'otherIncome8k',
                line: 'Line 8k',
                type: 'number',
            },
            {
                name: 'otherIncome8l',
                text: 'otherIncome8l',
                line: 'Line 8l',
                type: 'number',
            },
            {
                name: 'otherIncome8m',
                text: 'otherIncome8m',
                line: 'Line 8m',
                type: 'number',
            },
            {
                name: 'otherIncome8n',
                text: 'otherIncome8n',
                line: 'Line 8n',
                type: 'number',
            },
            {
                name: 'otherIncome8o',
                text: 'otherIncome8o',
                line: 'Line 8o',
                type: 'number',
            },
            {
                name: 'otherIncome8p',
                text: 'otherIncome8p',
                line: 'Line 8p',
                type: 'number',
            },
            {
                name: 'otherIncome8z',
                text: 'otherIncome8z',
                line: 'Line 8z',
                type: 'number',
            },
            {
                name: 'additionalIncomeTotal',
                text: 'Additional Income Total',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'sum1to9',
                text: 'Sum lines 1 to 9',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'educatorExpenses',
                text: 'Educator Expenses',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'certainBusinessExpenses',
                text: 'Certain Business Expenses',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'healthSavings',
                text: 'Health savings',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'movingExpenses',
                text: 'Moving Expenses',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'deductibleOfSelfEmployment',
                text: 'Deductible of self employment',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'selfEmployedSEP',
                text: 'Self employed SEP',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'selfEmployedHealthInsurance',
                text: 'Self employed Health Insurance',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'penaltyEarlyWithdrawal',
                text: 'Penalty Early Withdrawal',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'alimonyPaid',
                text: 'Alimony paid',
                line: 'Line 19a',
                type: 'number',
            },
            {
                name: 'iraDeduction',
                text: 'IRA deduction',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'studentLoanInterestDeduction',
                text: 'Student loan interest deduction',
                line: 'Line 21',
                type: 'number',
            },
            {
                name: 'forFutureUseSch1',
                text: 'For Future Use Sch1',
                line: 'Line 22',
                type: 'number',
            },
            {
                name: 'archerMSADeduction',
                text: 'Archer MSA Deduction',
                line: 'Line 23',
                type: 'number',
            },
            {
                name: 'juryDuty',
                text: 'Jury Duty',
                line: 'Line 24a',
                type: 'number',
            },
            {
                name: 'deductibleExpenses',
                text: 'Deductible Expenses',
                line: 'Line 24b',
                type: 'number',
            },
            {
                name: 'nontaxableAmountOlympicMedals',
                text: 'Nontaxable Amount Olympic Medals',
                line: 'Line 24c',
                type: 'number',
            },
            {
                name: 'reforestationAmortization',
                text: 'Reforestation Amortization',
                line: 'Line 24d',
                type: 'number',
            },
            {
                name: 'repaymentOfUnemployedBenefits',
                text: 'Repayment Of Unemployed Benefits',
                line: 'Line 24e',
                type: 'number',
            },
            {
                name: 'contributionsToSection501',
                text: 'Contributions to Section 501',
                line: 'Line 24f',
                type: 'number',
            },
            {
                name: 'contributionsToSection403',
                text: 'Contributions to Section 403',
                line: 'Line 24g',
                type: 'number',
            },
            {
                name: 'attorneyFeesDiscriminationClaims',
                text: 'Attorney Fees Discrimination Claims',
                line: 'Line 24h',
                type: 'number',
            },
            {
                name: 'attorneyFeesIRSHelp',
                text: 'Attorney Fees IRS Help',
                line: 'Line 24i',
                type: 'number',
            },
            {
                name: 'housingDeduction',
                text: 'Housing Deduction',
                line: 'Line 24j',
                type: 'number',
            },
            {
                name: 'excessDeductions',
                text: 'Excess Deductions',
                line: 'Line 24k',
                type: 'number',
            },
            {
                name: 'otherAdjustmens',
                text: 'Other Adjustmens',
                line: 'Line 24z',
                type: 'number',
            },
            {
                name: 'totalOtherAdjustments',
                text: 'Total Other Adjustmens',
                line: 'Line 25',
                type: 'number',
            },
            {
                name: 'adjustmentToIncome',
                text: 'Adjustments to income',
                line: 'Line 26',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 2',
        fields: [
            {
                name: 'clientHasSchedule2',
                text: 'Has Schedule 2?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name:'alternativeMinTax',
                text: 'Alternative Min Tax',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'excessAdvPremiumTaxCredit',
                text: 'Excess Adv Premium Tax Credit',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'sum1and2',
                text: 'Sum of lines 1 and 2',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'selfEmploymentTaxSch3',
                text: 'Self Employment Tax',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'socialSecurityTip',
                text: 'Social Security from Tips',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'uncollectedSocialSecurity',
                text: 'Uncollected Social Security',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'totalAdditionalSocialSecurity',
                text: 'Total Additional Social Security',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'additionalTaxOnIRA',
                text: 'Additional Tax On IRA',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'householdEmploymentTaxes',
                text: 'Household Employment Taxes',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'repaymentFirstTimeBuyer',
                text: 'Repayment First Time Buyer',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'additionalMedicare',
                text: 'Additional Medicare',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'netInvestmentIncomeTax',
                text: 'Net Investment Income Tax',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'uncollectedSocialSecurityOnTips',
                text: 'Uncollected Social Security On Tips',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'interestOnTax',
                text: 'Interest On Tax',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'interestOnDeferredTax',
                text: 'Interest On Deferred Tax',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'recaptureLowIncome',
                text: 'Recapture Low Income',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'recaptureOtherCredits',
                text: 'Recapture Other Credits',
                line: 'Line 17a',
                type: 'number',
            },
            {
                name: 'recaptureFederalMortage',
                text: 'Recapture Federal Mortages',
                line: 'Line 17b',
                type: 'number',
            },
            {
                name: 'additionalTaxHSA',
                text: 'Additional Tax HSA',
                line: 'Line 17c',
                type: 'number',
            },
            {
                name: 'additionalTaxHSAdidntRemainElegibleIndividual',
                text: 'Additional Tax HSA didnt Remain Elegible Individual',
                line: 'Line 17d',
                type: 'number',
            },
            {
                name: 'additionalTaxArcherMSA',
                text: 'Additional Tax Archer MSA',
                line: 'Line 17e',
                type: 'number',
            },
            {
                name: 'additionalTaxMedicareAdvantageMSA',
                text: 'Additional Tax Medicare Advantage MSA',
                line: 'Line 17f',
                type: 'number',
            },
            {
                name: 'recaptureOfCharitableContributions',
                text: 'Recapture Of Charitable Contributions',
                line: 'Line 17g',
                type: 'number',
            },
            {
                name: 'incomeFromNonqualifiedCompesationPlan',
                text: 'Income From Nonqualified Compesation Plan',
                line: 'Line 17h',
                type: 'number',
            },
            {
                name: 'incomeFromNonqualifiedCompesationPlan457A',
                text: 'Income From Nonqualified Compesation Plan 457A',
                line: 'Line 17i',
                type: 'number',
            },
            {
                name: 'section72ExcessBenefitsTax',
                text: 'Section 72 Excess Benefits Tax',
                line: 'Line 17j',
                type: 'number',
            },
            {
                name: 'goldenParachutePayments',
                text: 'Golden Parachute Payments',
                line: 'Line 17k',
                type: 'number',
            },
            {
                name: 'taxAccumulationDistributionOfTrusts',
                text: 'Tax Accumulation Distribution Of Trusts',
                line: 'Line 17l',
                type: 'number',
            },
            {
                name: 'exciseTaxOnInsiderStock',
                text: 'Excise Tax On Insider Stock',
                line: 'Line 17m',
                type: 'number',
            },
            {
                name: 'lookbackInterestSection167or460',
                text: 'Lookback Interest Section 167 or 460',
                line: 'Line 17n',
                type: 'number',
            },
            {
                name: 'taxNonEffectivelyConnectedIncome',
                text: 'Tax Non Effectively Connected Income',
                line: 'Line 17o',
                type: 'number',
            },
            {
                name: 'interestForm8621line16f',
                text: 'Interest Form 8621 line 16f',
                line: 'Line 17p',
                type: 'number',
            },
            {
                name: 'interestForm8621line24',
                text: 'Interest Form 8621 line 24',
                line: 'Line 17q',
                type: 'number',
            },
            {
                name: 'anyOtherTaxes',
                text: 'Any Other Taxes',
                line: 'Line 17z',
                type: 'number',
            },
            {
                name: 'totalAdditionalTaxes',
                text: 'Total Additional Taxes',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'reservedForFutureUse',
                text: 'Reserved For Future Use',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'section965',
                text: 'Section 965',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'totalOtherTaxes',
                text: 'Total Other Taxes',
                line: 'Line 21',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 3',
        fields: [
            {
                name: 'clientHasSchedule3',
                text: 'Has Schedule 3?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'foreignTax',
                text: 'Foreign tax',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'creditForChildCareTF',
                text: 'Credit for child/dependent care?',
                line: 'Line 2',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'creditForChildCare',
                text: 'Credit for child/dependent care',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'educationCredits',
                text: 'Education credits',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'retiramentSavings',
                text: 'Retirament Savings',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'energyCredits',
                text: 'Residential energy credits',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'generalBusinessCredit',
                text: 'General Business Credit',
                line: 'Line 6a',
                type: 'number',
            },
            {
                name: 'creditForPriorYearMinimunTax',
                text: 'Credit For Prior Yea rMinimun Tax',
                line: 'Line 6b',
                type: 'number',
            },
            {
                name: 'adoptionCredit',
                text: 'Adoption Credit',
                line: 'Line 6c',
                type: 'number',
            },
            {
                name: 'creditForEldery',
                text: 'credit For Eldery',
                line: 'Line 6d',
                type: 'number',
            },
            {
                name: 'alternativeMotorVehicleCredit',
                text: 'alternative Moto Vehicle Credit',
                line: 'Line 6e',
                type: 'number',
            },
            {
                name: 'qualifiedPlugInMotorVehicleCredit',
                text: 'qualified Plug In Motor Vehicle Credit',
                line: 'Line 6f',
                type: 'number',
            },
            {
                name: 'mortgageInterestCredit',
                text: 'Mortgage Interest Credit',
                line: 'Line 6g',
                type: 'number',
            },
            {
                name: 'columbiaFirstTimeBuyer',
                text: 'Columbia First Time Buyer',
                line: 'Line 6h',
                type: 'number',
            },
            {
                name: 'qualifiedElectricVehicleCredit',
                text: 'qualified Electric Vehicle Credit',
                line: 'Line 6i',
                type: 'number',
            },
            {
                name: 'alternativeFuelVehicleCredit',
                text: 'alternative Fuel Vehicle Credit',
                line: 'Line 6j',
                type: 'number',
            },
            {
                name: 'creditToHoldersTaxCreditsBonds',
                text: 'credit To Holders Tax Credits Bonds',
                line: 'Line 6k',
                type: 'number',
            },
            {
                name: 'amountOnForm8978',
                text: 'amount On Form 8978',
                line: 'Line 6l',
                type: 'number',
            },
            {
                name: 'otherNonRefundableCredits',
                text: 'other Non Refundable Credits',
                line: 'Line 6z',
                type: 'number',
            },
            {
                name: 'estimatedTax',
                text: 'Estimated tax',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'netPremiumTaxCredit',
                text: 'net Premium Tax Credit',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'requestForExtension',
                text: 'request For Extension',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'excessSocialSecurity',
                text: 'excess Social Security',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'creditForFederalTaxFuels',
                text: 'credit For Federal Tax Fuels',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'form2439',
                text: 'form 2439',
                line: 'Line 13a',
                type: 'number',
            },
            {
                name: 'qualifiedSickCredits',
                text: 'qualified Sick Credits',
                line: 'Line 13b',
                type: 'number',
            },
            {
                name: 'healthCoverageCredit',
                text: 'health Coverage Credit',
                line: 'Line 13c',
                type: 'number',
            },
            {
                name: 'creditForRepayment',
                text: 'credit For Repayment',
                line: 'Line 13d',
                type: 'number',
            },
            {
                name: 'futureUse13e',
                text: 'future Use 13e',
                line: 'Line 13e',
                type: 'number',
            },
            {
                name: 'deferredAmount965',
                text: 'deferred Amount 965',
                line: 'Line 13f',
                type: 'number',
            },
            {
                name: 'creditChildDependent2441',
                text: 'credit Child Dependent 2441',
                line: 'Line 13g',
                type: 'number',
            },
            {
                name: 'qualifiedSickCreditsSchH',
                text: 'qualified Sick Credits SchH',
                line: 'Line 13h',
                type: 'number',
            },
            {
                name: 'otherPaymentsOrRefundableCredits',
                text: 'other Payments Or Refundable Credits',
                line: 'Line 13z',
                type: 'number',
            },
            {
                name: 'refundableCredits',
                text: 'Refundable Credits',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'sum9to14',
                text: 'sum 9 to 14',
                line: 'Line 15',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule A',
        fields: [
            {
                name: 'clientHasScheduleA',
                text: 'Has Schedule A?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'medicalExpenses',
                text: 'Medical and dental expenses',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'medicalExpenses2',
                text: 'Multiply line 2 by 7.5%',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'medicalExpenses3',
                text: 'Line 3 minus line 1',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5a',
                text: 'Taxes you paid 5a',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5b',
                text: 'Taxes you paid 5b',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5c',
                text: 'Taxes you paid 5c',
                line: 'Line 5c',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5d',
                text: 'Taxes you paid 5d',
                line: 'Line 5d',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5e',
                text: 'Taxes you paid 5e',
                line: 'Line 5e',
                type: 'number',
            },
            {
                name: 'taxesYouPaid',
                text: 'Deductions allowed for taxes paid',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'mortgageInterests',
                text: 'line 8a',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'mortgageInterestsNotReported',
                text: 'line 8b',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'pointsNotReported',
                text: 'line 8c',
                line: 'Line 8c',
                type: 'number',
            },
            {
                name: 'mortgageInsurancePremiums',
                text: 'line 8d',
                line: 'Line 8d',
                type: 'number',
            },
            {
                name: 'sumOfLines8',
                text: 'line 8e',
                line: 'Line 8e',
                type: 'number',
            },
            {
                name: 'investmentInterest',
                text: 'Investment interest',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'interestYouPaid',
                text: 'Interest you paid (total)',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'giftsByCash',
                text: 'Gifts by cash or check',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'giftsByLand',
                text: 'Other than by cash or check',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'giftsToCharity',
                text: 'Gifts to charity',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'casualtyLosses',
                text: 'Casualty and theft losses',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'otherItemizedDeductions',
                text: 'Other itemized deductions',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'summationsItemizedDeductions',
                text: 'Total itemized deductions',
                line: 'Line 17',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule B Part 1',
        fields: [
            {
                name: 'clientHasScheduleB',
                text: 'Has Schedule B?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'scheduleb_1_0_text',
                text: 'scheduleb_1_0_text',
                line: 'Line 0 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_0_amount',
                text: 'scheduleb_1_0_amount',
                line: 'Line 0 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_1_text',
                text: 'scheduleb_1_1_text',
                line: 'Line 1 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_1_amount',
                text: 'scheduleb_1_1_amount',
                line: 'Line 1 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_2_text',
                text: 'scheduleb_1_2_text',
                line: 'Line 2 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_2_amount',
                text: 'scheduleb_1_2_amount',
                line: 'Line 2 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_3_text',
                text: 'scheduleb_1_3_text',
                line: 'Line 3 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_3_amount',
                text: 'scheduleb_1_3_amount',
                line: 'Line 3 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_4_text',
                text: 'scheduleb_1_4_text',
                line: 'Line 4 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_4_amount',
                text: 'scheduleb_1_4_amount',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_5_text',
                text: 'scheduleb_1_5_text',
                line: 'Line 5 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_5_amount',
                text: 'scheduleb_1_5_amount',
                line: 'Line 5 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_6_text',
                text: 'scheduleb_1_6_text',
                line: 'Line 6 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_6_amount',
                text: 'scheduleb_1_6_amount',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_7_text',
                text: 'scheduleb_1_7_text',
                line: 'Line 7 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_7_amount',
                text: 'scheduleb_1_7_amount',
                line: 'Line 7 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_8_text',
                text: 'scheduleb_1_8_text',
                line: 'Line 8 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_8_amount',
                text: 'scheduleb_1_8_amount',
                line: 'Line 8 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_9_text',
                text: 'scheduleb_1_9_text',
                line: 'Line 9 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_9_amount',
                text: 'scheduleb_1_9_amount',
                line: 'Line 9 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_10_text',
                text: 'scheduleb_1_10_text',
                line: 'Line 10 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_10_amount',
                text: 'scheduleb_1_10_amount',
                line: 'Line 10 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_11_text',
                text: 'scheduleb_1_11_text',
                line: 'Line 11 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_11_amount',
                text: 'scheduleb_1_11_amount',
                line: 'Line 11 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_12_text',
                text: 'scheduleb_1_12_text',
                line: 'Line 12 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_12_amount',
                text: 'scheduleb_1_12_amount',
                line: 'Line 12 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_13_text',
                text: 'scheduleb_1_13_text',
                line: 'Line 13 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_13_amount',
                text: 'scheduleb_1_13_amount',
                line: 'Line 13 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_14_text',
                text: 'scheduleb_1_14_text',
                line: 'Line 14 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_14_amount',
                text: 'scheduleb_1_14_amount',
                line: 'Line 14 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_15_text',
                text: 'scheduleb_1_15_text',
                line: 'Line 15 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_15_amount',
                text: 'scheduleb_1_15_amount',
                line: 'Line 15 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_2',
                text: 'scheduleb line 2',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'scheduleb_4',
                text: 'scheduleb line 4',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule  B Part 2',
        fields: [
            // line 5
            {
                name: 'scheduleb_5_0_text',
                text: 'scheduleb_5_0_text',
                line: 'Line 0 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_0_amount',
                text: 'scheduleb_5_0_amount',
                line: 'Line 0 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_1_text',
                text: 'scheduleb_5_1_text',
                line: 'Line 1 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_1_amount',
                text: 'scheduleb_5_1_amount',
                line: 'Line 1 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_2_text',
                text: 'scheduleb_5_2_text',
                line: 'Line 2 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_2_amount',
                text: 'scheduleb_5_2_amount',
                line: 'Line 2 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_3_text',
                text: 'scheduleb_5_3_text',
                line: 'Line 3 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_3_amount',
                text: 'scheduleb_5_3_amount',
                line: 'Line 3 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_4_text',
                text: 'scheduleb_5_4_text',
                line: 'Line 4 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_4_amount',
                text: 'scheduleb_5_4_amount',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_5_text',
                text: 'scheduleb_5_5_text',
                line: 'Line 5 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_5_amount',
                text: 'scheduleb_5_5_amount',
                line: 'Line 5 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_6_text',
                text: 'scheduleb_5_6_text',
                line: 'Line 6 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_6_amount',
                text: 'scheduleb_5_6_amount',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_7_text',
                text: 'scheduleb_5_7_text',
                line: 'Line 7 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_7_amount',
                text: 'scheduleb_5_7_amount',
                line: 'Line 7 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_8_text',
                text: 'scheduleb_5_8_text',
                line: 'Line 8 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_8_amount',
                text: 'scheduleb_5_8_amount',
                line: 'Line 8 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_9_text',
                text: 'scheduleb_5_9_text',
                line: 'Line 9 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_9_amount',
                text: 'scheduleb_5_9_amount',
                line: 'Line 9 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_10_text',
                text: 'scheduleb_5_10_text',
                line: 'Line 10 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_10_amount',
                text: 'scheduleb_5_10_amount',
                line: 'Line 10 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_11_text',
                text: 'scheduleb_5_11_text',
                line: 'Line 11 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_11_amount',
                text: 'scheduleb_5_11_amount',
                line: 'Line 11 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_12_text',
                text: 'scheduleb_5_12_text',
                line: 'Line 12 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_12_amount',
                text: 'scheduleb_5_12_amount',
                line: 'Line 12 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_13_text',
                text: 'scheduleb_5_13_text',
                line: 'Line 13 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_13_amount',
                text: 'scheduleb_5_13_amount',
                line: 'Line 13 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_14_text',
                text: 'scheduleb_5_14_text',
                line: 'Line 14 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_14_amount',
                text: 'scheduleb_5_14_amount',
                line: 'Line 14 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_15_text',
                text: 'scheduleb_5_15_text',
                line: 'Line 15 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_15_amount',
                text: 'scheduleb_5_15_amount',
                line: 'Line 15 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_6',
                text: 'scheduleb line 6',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule C',
        fields: [
            {
                name: 'clientHasScheduleC',
                text: 'Has Schedule C?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'grossReceipts',
                text: 'Gross receipts',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'returns',
                text: 'Returns',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'line3SchC',
                text: 'line 3',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'costOfGoods',
                text: 'Cost of goods',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'grossProfit',
                text: 'Gross Profit',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'otherIncomeSchC',
                text: 'Other income sch C',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'grossIncome',
                text: 'Gross income',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'advertising',
                text: 'advertising',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'carExpenses',
                text: 'car Expenses',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'commissions',
                text: 'commissions',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'contractLabor',
                text: 'Contract labor',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'depletion',
                text: 'depletion',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'depreciation',
                text: 'depreciation',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'employeeBenefit',
                text: 'Employee benefit',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'insurance',
                text: 'insurance',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'interestMortgage',
                text: 'interest Mortgage',
                line: 'Line 16a',
                type: 'number',
            },
            {
                name: 'interestOther',
                text: 'interest Other',
                line: 'Line 16b',
                type: 'number',
            },
            {
                name: 'legal',
                text: 'Legal',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'officeExpenses',
                text: 'office Expenses',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'pensionPlans',
                text: 'pension Plans',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'vehiclesMachinery',
                text: 'vehicles Machinery',
                line: 'Line 20a',
                type: 'number',
            },
            {
                name: 'otherBusinessProperty',
                text: 'other Business Property',
                line: 'Line 20b',
                type: 'number',
            },
            {
                name: 'repairs',
                text: 'repairs',
                line: 'Line 21',
                type: 'number',
            },
            {
                name: 'supplies',
                text: 'supplies',
                line: 'Line 22',
                type: 'number',
            },
            {
                name: 'taxesAndLicenses',
                text: 'taxes And Licenses',
                line: 'Line 23',
                type: 'number',
            },
            {
                name: 'travel',
                text: 'travel',
                line: 'Line 24a',
                type: 'number',
            },
            {
                name: 'meals',
                text: 'meals',
                line: 'Line 24b',
                type: 'number',
            },
            {
                name: 'utilities',
                text: 'utilities',
                line: 'Line 25',
                type: 'number',
            },
            {
                name: 'wagesLessEmployeeCredits',
                text: 'Wages less employee credits',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'otherExpenses_schC',
                text: 'other Expenses schC',
                line: 'Line 27a',
                type: 'number',
            },
            {
                name: 'totalExpenses',
                text: 'total Expenses',
                line: 'Line 28',
                type: 'number',
            },
            {
                name: 'tentativeProfit',
                text: 'tentative Profit',
                line: 'Line 29',
                type: 'number',
            },
            {
                name: 'expensesForHomeBusinessTF',
                text: 'Expenses for business use of your home?',
                line: 'Line 30',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'expensesForHomeBusiness',
                text: 'Expenses for business use of your home',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'netProfitOrLoss',
                text: 'Net profit or (loss) from business',
                line: 'Line 31',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule D',
        fields: [
            {
                name: 'clientHasScheduleD',
                text: 'Has Schedule D?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'shortTermLossCarryover',
                text: 'short Term Loss Carryover',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'netShortTermCapitalGains',
                text: 'Net short-term capital gain or (loss)',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'longTermLossCarryover',
                text: 'long Term Loss Carryover',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'netLongTermCapitalGains',
                text: 'Net long-term capital gain (or loss)',
                line: 'Line 15',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule E',
        fields: [
            {
                name: 'clientHasScheduleE',
                text: 'Has Schedule E?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'totalAmounts23a',
                text: 'totalAmounts23a',
                line: 'Line 23a',
                type: 'number',
            },
            {
                name: 'totalAmounts23b',
                text: 'totalAmounts23b',
                line: 'Line 23b',
                type: 'number',
            },
            {
                name: 'totalAmounts23c',
                text: 'totalAmounts23c',
                line: 'Line 23c',
                type: 'number',
            },
            {
                name: 'totalAmounts23d',
                text: 'totalAmounts23d',
                line: 'Line 23d',
                type: 'number',
            },
            {
                name: 'totalAmounts23e',
                text: 'totalAmounts23e',
                line: 'Line 23e',
                type: 'number',
            },
            {
                name: 'incomeSchE',
                text: 'income Sch E',
                line: 'Line 24',
                type: 'number',
            },
            {
                name: 'lossesSchE',
                text: 'losses Sch E',
                line: 'Line 25',
                type: 'number',
            },
            {
                name: 'totalRentalRealEstate',
                text: 'total Rental Real Estate',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'line30schE',
                text: 'line30schE',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'line31schE',
                text: 'line31schE',
                line: 'Line 31',
                type: 'number',
            },
            {
                name: 'line32schE',
                text: 'line32schE',
                line: 'Line 32',
                type: 'number',
            },
            {
                name: 'line35schE',
                text: 'line35schE',
                line: 'Line 35',
                type: 'number',
            },
            {
                name: 'line36schE',
                text: 'line36schE',
                line: 'Line 36',
                type: 'number',
            },
            {
                name: 'line37schE',
                text: 'line37schE',
                line: 'Line 37',
                type: 'number',
            },
            {
                name: 'line39schE',
                text: 'line39schE',
                line: 'Line 39',
                type: 'number',
            },
            {
                name: 'line40schE',
                text: 'line40schE',
                line: 'Line 40',
                type: 'number',
            },
            {
                name: 'line41schE',
                text: 'line41schE',
                line: 'Line 41',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule SE',
        fields: [
            {
                name: 'clientHasScheduleSE',
                text: 'Has Schedule SE?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'netFarmProfitOrLoss',
                text: 'net Farm Profit Or Loss',
                line: 'Line 1a',
                type: 'number',
            },
            {
                name: 'conservationReserveProgramPayments',
                text: 'conservation Reserve Program Payments',
                line: 'Line 1b',
                type: 'number',
            },
            {
                name: 'netProfitOrLossFromSchC',
                text: 'net Profit Or Loss From SchC',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'combinelines1a1b2',
                text: 'combine lines 1a 1b and 2',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'line4aSchSE',
                text: 'line4aSchSE',
                line: 'Line 4a',
                type: 'number',
            },
            {
                name: 'line4bSchSE',
                text: 'line4bSchSE',
                line: 'Line 4b',
                type: 'number',
            },
            {
                name: 'line4cSchSE',
                text: 'line4cSchSE',
                line: 'Line 4c',
                type: 'number',
            },
            {
                name: 'churchEmployeeIncome',
                text: 'church Employee Income',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'line5bSchSE',
                text: 'line 5b SchSE',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'addLines4c5b',
                text: 'add Lines 4c 5b',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'maximumAmountofCombinedWages',
                text: 'maximum amount of Combined Wages',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'totalSocialSecurityWages',
                text: 'total Social Security Wages',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'unreportedTips',
                text: 'unreported Tips',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'wagesSubjectToSocialSecurity',
                text: 'wages Subject To Social Security',
                line: 'Line 8c',
                type: 'number',
            },
            {
                name: 'add8a8b8c',
                text: 'add 8a 8b and 8c',
                line: 'Line 8d',
                type: 'number',
            },
            {
                name: 'line9SchSE',
                text: 'line 9 SchSE',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'line10SchSE',
                text: 'line 10 SchSE',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'line11SchSE',
                text: 'line 11 SchSE',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'selfEmploymentTax',
                text: 'line 12 SchSE',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'deductionForOneHalfOfSelfemployment',
                text: 'deduction For One Half Of Selfemployment',
                line: 'Line 13',
                type: 'number',
            },
        ],
    },
    {
        section: 'Form 8995',
        fields: [
            {
                name: 'clientHasForm8995',
                text: 'Has Form 8995?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'totalQualifiedBusinessIncome',
                text: 'Total qualified business income',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'qualifiedBusinessIncomeDeduction',
                text: 'qualified Business Income Deduction',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'totalReit',
                text: 'total Reit',
                line: 'Line 17',
                type: 'number',
            },
            
        ],
    },
    {
        section: 'Other Ones',
        fields: [
            {
                name: 'contributionMade457_1',
                text: 'Contribution made to plan 457',
                line: 'Form 8880 Line 2',
                type: 'number',
            },
            {
                name: 'contributionMade457_2',
                text: 'Spouse contribution made to plan 457',
                line: 'Form 8880 Line 2',
                type: 'number',
            },
            {
                name: 'line3form2441',
                text: 'line 3 form 2441',
                line: 'Line 3 form 2441',
                type: 'number',
            },
            {
                name: 'clientHasForm2441',
                text: 'Has Form 2441?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'hasChildren',
                text: 'Has children?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'hasForm8863',
                text: 'Has Form 8863?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm1120',
                text: 'Has Form 1120?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm1120S',
                text: 'Has Form 1120S?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm8839',
                text: 'Has Form 8839?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm6251',
                text: 'Has Form 6251?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm5695',
                text: 'Has Form 5695?',
                type: 'string',
                options: ['Yes', 'No'],
            },
        ],
    },
]