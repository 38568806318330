import { Header, Table } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { prettyDate } from "../../../helpers/moment-helpers";
import { useHistory } from "react-router-dom";

const CompanyAdvisorsTab = ({ activeTab }) => {
  const history = useHistory();
  const companyProfile = useSelector((state) => state.currentCompany);

  return (
    <div>
      {activeTab === "advisors" && (
        <div>
          <Header as="h3">
            <Header.Content>
              All Advisors ({companyProfile?.advisorList?.length})
            </Header.Content>
          </Header>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Full Name</Table.HeaderCell>
                <Table.HeaderCell>E-mail</Table.HeaderCell>
                {/* <Table.HeaderCell>POC</Table.HeaderCell> */}
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>2FA</Table.HeaderCell>
                <Table.HeaderCell>License Expires</Table.HeaderCell>
                <Table.HeaderCell>Last Login</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {companyProfile?.advisorList?.map((advisor, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <div style={{cursor: 'pointer'}} onClick={() => history.push(`/dashboard/advisors/${advisor._id}`)}>
                      {advisor?.fullName}
                    </div>
                  </Table.Cell>
                  <Table.Cell>{advisor?.email}</Table.Cell>
                  {/* <Table.Cell>{companyProfile?.poc?.fullName}</Table.Cell> */}
                  <Table.Cell>{advisor?.status}</Table.Cell>
                  <Table.Cell>
                    {advisor?.hasEnabled2FA ? "Active" : "Inactive"}
                  </Table.Cell>
                  <Table.Cell>
                    {prettyDate({ date: advisor?.licenseExpiresIn })}
                  </Table.Cell>
                  <Table.Cell>
                    {prettyDate({ date: advisor?.activity?.lastLogin })}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  );
};

export default CompanyAdvisorsTab;
