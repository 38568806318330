/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    Label,
    Tab,
    Table,
    Icon,
    Input,
    Dropdown,
    Dimmer,
    Loader,
    Segment,
    Modal,
    Form,
    TextArea,
} from "semantic-ui-react"
import { sysAPI } from "../../apis/SysAPI";
import { ComponentTemplate } from "../../templates/ComponentTemplate";

const LoaderCustom = () => (
    <div
        style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
        }}
    >
        <Segment>
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
        </Segment>
    </div>
);

const ContinueReviewModal = ({ clientId, index, hideModal, showModal, refreshList }) => {
    const messageArea = useRef();
    const [message, setMessage] = useState('');
    const [unfilled, setUnfilled] = useState(false);

    const handleMessage = e => {
        setMessage(e.target.value);
    };

    const continueReview = () => {
        if (!message || message.length <= 5) {
        setUnfilled('You are required to enter a reason for submitting on hold.');
        return true;
        }

        console.log(clientId)
        console.log(index)

        if (unfilled) setUnfilled(false);
        sysAPI.post('/estate-manual-input/continuereview', {clientId, index, message})
        .then(res => {
            if (res.status === 200) {
            refreshList(prev => (prev + 1));
            hideModal(null);
            }
        })
        .catch(err => setUnfilled(err.message));
    };

    useEffect(() => {
        messageArea.current.focus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Modal open={showModal !== null}>
            <Modal.Header>Why do you want to continue reviewing this E.M.I.R.?</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <TextArea ref={messageArea} value={message} onChange={handleMessage} />
                    </Form.Field>
                </Form>
                <p style={{color: 'red', marginTop: '0.8em'}}>{unfilled || ''}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => hideModal(null)}>Cancel</Button>
                <Button
                    onClick={continueReview}
                    color="purple"
                >
                    Send to hold
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const RequestsTable = ({ stage, requests, setSort, setDirection, sort, direction, refreshList }) => {
    const me = useSelector(state => state.me);
    const [showContinueReviewModal, setShowContinueReviewModal] = useState(false);
    const [clientId, setclientId] = useState(undefined);
    const [index, setIndex] = useState(undefined);
    // const filteredRequests = requests?.filter(r => r.status === stage) || [];
    // const [filteredRequests, setFilteredRequests] = useState(requests?.filter(r => r.status === stage) || []);
    // const [orderedRequests, setOrderedRequests] = useState(undefined);
    // const [sort, setSort] = useState('requestDate');
    // const [direction, setdirection] = useState('DESC');
    // const [sortChange, setSortChange] = useState(0);

    // console.log('request:', requests);
    // console.log('filtered:', filteredRequests);
    // console.log('ordered:', orderedRequests);

    const reviewEstateBtn_Click = (clientId, index) => {
        return () => {
            sysAPI.post(`/estate-manual-input/review`, {
                clientId,
                index
            }).then(response => {
                window.location.replace(`/dashboard/estate-manual-input/${clientId}/${index}`)
                console.log(response.data);
            }).catch(err => {
                console.error(err.response);
            });
        }
    }

    const getLabel = (request) => {
        switch (request.status) {
            case 'Pending': return <Label color='red'>PENDING</Label>;
            case 'In review': return <Label color='blue'>IN REVIEW</Label>;
            case 'Peer review': return <Label color='orange'>PEER REVIEW</Label>;
            case 'On Hold': return <Label color='purple'>ON HOLD</Label>;
            case 'Ready': return <Label color='green'>READY</Label>;
            default: break;
        }
    }

    const sortColumn = async (column) => {
        if (column === sort) {
            setDirection(direction === 'ASC' ? 'DESC' : 'ASC');
            return
        }

        setSort(column);
        setDirection('DESC');
    }

    const handleContinue = async (client, ind) => {
        setclientId(client);
        setIndex(ind);
        setShowContinueReviewModal(true);
    }

    /* useEffect(() => {
        if (sortChange > 0) {
            // let newOrder = [];
            // switch (sort) {
            //     case 'requestDate':
            //         newOrder = requests.sort((a, b) => moment(b.date).diff(a.date));
            //         if (sortType === 'DESC') {
            //             newOrder = newOrder.reverse();
            //         }
            //         break;
            //     case 'finishDate':
            //         newOrder = requests.sort((a, b) => moment(b.finishReviewDate).diff(a.finishReviewDate));
            //         if (sortType === 'DESC') {
            //             newOrder = newOrder.reverse();
            //         }
            //         break;
            //     default:
            //         newOrder = requests.sort((a, b) => moment(b.etcTime).diff(a.etcTime));
            //         if (sortType === 'DESC') {
            //             newOrder = newOrder.reverse();
            //         }
            //         break;
            // }

            // console.log(newOrder.filter(r => r.status === stage));
            // setRequests(newOrder);

            const req = `?filter=${sort}&order=${direction}`;
            getRequests(req);
        }
    }, [sortChange]); */

    return (<>
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Client Name</Table.HeaderCell>
                    <Table.HeaderCell>Requested by</Table.HeaderCell>
                    <Table.HeaderCell
                        className={`table-header__order ${sort === 'company' ? 'active' : ''}`}
                        onClick={() => sortColumn('company')}
                    >
                        Company
                        {(sort === 'company' && direction === 'ASC') && <Icon name="sort up" />}
                        {(sort === 'company' && direction === 'DESC') && <Icon name="sort down" />}
                        {sort !== 'company' && <Icon name="sort" />}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        className={`table-header__order ${sort === 'poc' ? 'active' : ''}`}
                        onClick={() => sortColumn('poc')}
                    >
                        POC
                        {(sort === 'poc' && direction === 'ASC') && <Icon name="sort up" />}
                        {(sort === 'poc' && direction === 'DESC') && <Icon name="sort down" />}
                        {sort !== 'poc' && <Icon name="sort" />}
                    </Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell
                        className={`table-header__order ${sort === 'requestDate' ? 'active' : ''}`}
                        onClick={() => sortColumn('requestDate')}
                    >
                        Request Date
                        {(sort === 'requestDate' && direction === 'ASC') && <Icon name="sort up" />}
                        {(sort === 'requestDate' && direction === 'DESC') && <Icon name="sort down" />}
                        {sort !== 'requestDate' && <Icon name="sort" />}
                    </Table.HeaderCell>

                    {stage === 'Ready' &&
                    <>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'finishDate' ? 'active' : ''}`}
                            onClick={() => sortColumn('finishDate')}
                        >
                            Finish Date
                            {(sort === 'finishDate' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'finishDate' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'finishDate' && <Icon name="sort" />}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'etc' ? 'active' : ''}`}
                            onClick={() => sortColumn('etc')}
                        >
                            ETC
                            {(sort === 'etc' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'etc' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'etc' && <Icon name="sort" />}
                        </Table.HeaderCell>
                    </>}

                    {stage === 'In review' && <>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'timeInReview' ? 'active' : ''}`}
                            onClick={() => sortColumn('timeInReview')}
                        >
                            Time In Review
                            {(sort === 'timeInReview' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'timeInReview' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'timeInReview' && <Icon name="sort" />}
                        </Table.HeaderCell>
                    </>}

                    {stage === 'Pending' &&
                    <>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'timeInPending' ? 'active' : ''}`}
                            onClick={() => sortColumn('timeInPending')}
                        >
                            Time In Pending
                            {(sort === 'timeInPending' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'timeInPending' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'timeInPending' && <Icon name="sort" />}
                        </Table.HeaderCell>
                    </>}

                    {stage === 'Peer review' &&
                    <>
                        <Table.HeaderCell>Time In Review</Table.HeaderCell>
                    </>}

                    {(stage === 'In review' || stage === 'Peer review') && <>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'timeSinceRequest' ? 'active' : ''}`}
                            onClick={() => sortColumn('timeSinceRequest')}
                        >
                            Time Since Request
                            {(sort === 'timeSinceRequest' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'timeSinceRequest' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'timeSinceRequest' && <Icon name="sort" />}
                        </Table.HeaderCell>
                    </>}

                    <Table.HeaderCell>Reviewer</Table.HeaderCell>
                    {me.tags?.includes('reviewersGroup:*') &&
                        <Table.HeaderCell>Assigned to</Table.HeaderCell>
                    }

                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {requests.map((request, index) => {
                    return <Table.Row key={index}>
                        <Table.Cell>{request.client.displayName}</Table.Cell>
                        <Table.Cell>
                            {request.advisor.fullName}/
                            {request.advisor.isOnFreeTrialPeriod ? 'Trial' : request.advisor.hasSubscription  ? 'Purchased' : 'Expired'}
                        </Table.Cell>
                        <Table.Cell>{request.advisor.nameCompany || request.advisor.companyName}</Table.Cell>
                        <Table.Cell>{request.advisor.poc}</Table.Cell>
                        <Table.Cell>{getLabel(request)}</Table.Cell>
                        <Table.Cell>{moment(request.date).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
                        {
                            stage === 'Ready' &&
                            <Table.Cell>{moment(request.finishReviewDate).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
                        }
                        {/*{
                            stage === 'In review' &&
                            <Table.Cell>{moment(request.startReviewDate).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
                        }
                        {
                            stage === 'Peer review' &&
                            <Table.Cell>{moment(request.finishPeerReviewDate).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
                        } */}

                        <Table.Cell>{request.etc}</Table.Cell>

                        {(stage === 'In review' || stage === 'Peer review') &&
                            <Table.Cell>{request.timeSinceRequest}</Table.Cell>}

                        <Table.Cell>{request.reviewer?.firstName ? `${request.reviewer.firstName} ${request.reviewer.lastName}` : 'N/A'}{request.peerReviewer?.firstName ? ` / ${request.peerReviewer.firstName} ${request.peerReviewer.lastName}` : ''}</Table.Cell>
                        {me.tags?.includes('reviewersGroup:*') &&
                            <Table.Cell>{request.reviewersGroup.join(', ')}</Table.Cell>
                        }
                        <Table.Cell>
                            {request?.status === 'Pending' && request.client._id &&
                                // <Link to={`/dashboard/estate-manual-input/${request.client._id}/${request.index}`}>
                                    <Button color='green' onClick={reviewEstateBtn_Click(request.client._id, request.index)}>
                                        Start Reviewing
                                    </Button>
                                // </Link>
                            }
                            {request.status === 'In review' && (request.reviewer._id === me._id ) &&
                                <Link to={`/dashboard/estate-manual-input/${request.client._id}/${request.index}`}>
                                    <Button color='blue'>
                                        Continue Reviewing
                                    </Button>
                                </Link>
                            }
                            {request.status === 'On Hold' && me.scope.split(' ').includes('admin') &&
                                <Button color='purple' onClick={() => handleContinue(request.client._id, request.index)}>
                                    Finish Hold
                                </Button>
                            }
                            {request.status === 'Peer review' && 
                                <Link to={`/dashboard/estate-manual-input/${request.client._id}/${request.index}`}>
                                    <Button color='orange' onClick={reviewEstateBtn_Click(request.client._id, request.index)}>
                                        Finish Reviewing
                                    </Button>
                                </Link>
                            }
                            {(((request.status === 'In review' || request.status === 'Peer review') &&  me.scope.split(' ').includes('admin')) || request.status === 'Ready') &&
                                <Link to={`/dashboard/estate-manual-input/${request.client._id}/${request.index}`}>
                                    <Button>
                                        View
                                    </Button>
                                </Link>
                            }
                        </Table.Cell>
                    </Table.Row>
                })}
            </Table.Body>
        </Table>
        {showContinueReviewModal &&
            <ContinueReviewModal
                hideModal={setShowContinueReviewModal}
                showModal={showContinueReviewModal}
                refreshList={refreshList}
                clientId={clientId}
                index={index}
            />}
    </>)
}

export const EstateManualInputRequestsTab = () => {
    const [requests, setRequests] = useState([]);
    const [pending, setPending] = useState([]);
    const [inReview, setInReview] = useState([]);
    const [peerReview, setPeerReview] = useState([]);
    const [onHold, setOnHold] = useState([]);
    const [ready, setReady] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // eslint-disable-next-line
    const [changeMaps, setChangeMaps] = useState(0);
    const [requestDate, setRequestDate] = useState('');
    const [sort, setSort] = useState('requestDate');
    const [direction, setDirection] = useState('DESC');
    // eslint-disable-next-line
    const [initDate, setInitDate] = useState('');
    const [companiesList, setCompaniesList] = useState([]);
    const [companySelected, setCompanySelected] = useState('');
    const [pocsLists, setPocsLists] = useState([]);
    const [pocSelected, setPocSelected] = useState('');
    const [refreshList, setRefreshList] = useState(0);
    const me = useSelector(state => state.me);
    // const today = moment();

    /* const calculateETC = (startDate, finishDate) => {
        let time = 0;
        const date = moment(startDate);
        while (date.diff(finishDate) < 0) {
            if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
                let timeMeasure = 0
                if (moment(finishDate).diff(date) < 86400000){
                    timeMeasure = moment(finishDate).diff(date);
                }
                else {
                    timeMeasure = 86400000;
                }
                date.add(timeMeasure, 'milliseconds')
                time+=timeMeasure
            }
            else {
                date.add(1, 'days')
            }
        }
        const h = moment.duration(time)
        const t = h.humanize()
        return [t === 'a day' ? '1 day' : t === 'an hour' ? '1 hour' : t === 'a month' ? '1 month' : t, h]
    } */

    const getRequests = (params = '') => {
        sysAPI.get(`/estate-manual-input/requests${params}`).then(response => {
            const { requests } = response.data;
            if (!requests) {
                setRequests([]);
                return true;
            }

            // const sortedRequests = requests.sort((a, b) => moment(b.requestDate).diff(a.requestDate));
            /* const sortedRequests = requests;

            const etcCalc = [];
            for (const req in sortedRequests) {
                const current = sortedRequests[req];
                const etc = calculateETC(
                    current.status === 'In review' ? current.startReviewDate : current.status === 'Peer review' ?  current.finishPeerReviewDate : current.date, 
                    current.status === 'Ready' ? current.finishReviewDate : today);
                current.etc = etc[0];
                current.etcTime = etc[1];
                etcCalc.push(current);
            }

            setRequests(etcCalc); */

            if (requests.length === 0) {
                return window.alert('No matches found for your current filters.');
            }

            setRequests(requests);
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const handlerFilterByDate = () => {
        setIsLoading(true);
        let filter = '';
        if (companySelected !== '' && companySelected !== 'N/A') {
            filter = `tabFilter=company&tabValue=${companySelected}`;
        }

        if (pocSelected !== '' && pocSelected !== 'N/A') {
            filter = `tabFilter=poc&tabValue=${pocSelected}`;
        }

        const sortType = sort !== '' ? `filter=${sort}&` : '';
        const sortDirection = sort !== '' ? `order=${direction}&` : '';
        const sortDate = requestDate !== '' ? `initDate=${requestDate}&` : '';

        const filters = `?${sortType}${sortDirection}${sortDate}${filter}`;

        getRequests(filters);
    }

    /* useEffect(() => {
        // sysAPI.get(`/estate-manual-input/requests`).then(response => {
        //     const { requests } = response.data;
        //     if (!requests) return;

        //     const sortedRequests = requests.sort((a, b) => moment(b.requestDate).diff(a.requestDate));

        //     const etcCalc = [];
        //     for (const req in sortedRequests) {
        //         const current = sortedRequests[req];
        //         const etc = calculateETC(
        //             current.status === 'In review' ? current.startReviewDate : current.status === 'Peer review' ?  current.finishPeerReviewDate : current.date, 
        //             current.status === 'Ready' ? current.finishReviewDate : today);
        //         current.etc = etc[0];
        //         current.etcTime = etc[1];
        //         etcCalc.push(current);
        //     }

        //     setRequests(etcCalc);
        // }).catch(err => {
        //     console.error(err);
        // });
        getRequests();
    }, []); */

    useEffect(() => {
        setIsLoading(true);
        let filter = '';
        if (companySelected !== '' && companySelected !== 'N/A') {
            filter = `tabFilter=company&tabValue=${companySelected}`;
        }
        if (pocSelected !== '' && pocSelected !== 'N/A') {
            filter = `tabFilter=poc&tabValue=${pocSelected}`;
        }

        const sortType = sort !== '' ? `filter=${sort}&` : '';
        const sortDirection = sort !== '' ? `order=${direction}&` : '';
        const sortDate = requestDate !== '' ? `initDate=${requestDate}` : '';

        const filters = `?${sortType}${sortDirection}${sortDate}${filter}`;

        getRequests(filters);
    }, [sort, direction, initDate]);

    useEffect(() => {
        if (refreshList > 0) {
            setIsLoading(true);
            let filter = '';
            if (companySelected !== '' && companySelected !== 'N/A') {
                filter = `tabFilter=company&tabValue=${companySelected}`;
            }
            if (pocSelected !== '' && pocSelected !== 'N/A') {
                filter = `tabFilter=poc&tabValue=${pocSelected}`;
            }
    
            const sortType = sort !== '' ? `filter=${sort}&` : '';
            const sortDirection = sort !== '' ? `order=${direction}&` : '';
            const sortDate = requestDate !== '' ? `initDate=${requestDate}` : '';
    
            const filters = `?${sortType}${sortDirection}${sortDate}${filter}`;
    
            getRequests(filters);
        }
    }, [refreshList]);

    useEffect(() => {
        if (requests.length >= 1) {
            const mapPending = requests.filter(r => r.status === 'Pending') || [];
            const mapInReview = requests.filter(r => r.status === 'In review') || [];
            const mapPeerReview = requests.filter(r => r.status === 'Peer review') || [];
            const mapOnHold = requests.filter(r => r.status === 'On Hold') || [];
            const mapReady = requests.filter(r => r.status === 'Ready') || [];

            setPending(mapPending);
            setInReview(mapInReview);
            setPeerReview(mapPeerReview);
            setOnHold(mapOnHold);
            setReady(mapReady);
        }
    }, [requests]);

    useEffect(() => {
        if (changeMaps >= 1) {
            setIsLoading(true);
            getRequests();
        }
    }, [changeMaps]);

    useEffect(() => {
        sysAPI.get(`/companies/emi`).then(response => {
            const { companiesList } = response.data;
            const list = companiesList.map(company => ({ key: company, text: company, value: company }))
            list.unshift({ key: 'all', text: 'All', value: '' });
            setCompaniesList(list);
        }).catch(err => {
            console.error(err);
        });

        sysAPI.get('/companies/pocs').then(response => {
            const pocs = response.data.map(advisor => ({ key: advisor._id, text: advisor.name, value: advisor.name}));
            pocs.unshift({ key: 'all', text: 'All', value: '' });
            setPocsLists(pocs);
        }).catch(err => {
            console.error(err);
        });

        setIsLoading(false);
    }, []);

    const countRequests = (status) => {
        return requests?.filter(r => r.status === status).length;
    }

    const panes = () => ([
        { menuItem: `Pending (${countRequests('Pending')})`, render: () => <RequestsTable requests={pending} stage='Pending' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
        { menuItem: `In Review (${countRequests('In review')})`, render: () => <RequestsTable requests={inReview} stage='In review' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
        { menuItem: `On Hold (${countRequests('On Hold')})`, render: () => <RequestsTable requests={onHold} stage='On Hold' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} refreshList={setRefreshList} /> },
        // (me.tags?.includes('reviewerGroup:*') || me.tags?.includes('reviewerGroup:solomon'))
        //     && { menuItem: `Under Peer Review (${countRequests('Peer review')})`, render: () => <RequestsTable requests={peerReview} stage='Peer review' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
        { menuItem: `Ready (${countRequests('Ready')})`, render: () => <RequestsTable requests={ready} stage='Ready' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
    ]);

    return (
        <ComponentTemplate scope={'estate_manual_input'}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '12px',
                }}
            >
                <Dropdown
                    placeholder='Filter by Company'
                    selection
                    search
                    options={companiesList}
                    value={companySelected}
                    onChange={(e, { value }) => {setPocSelected(''); setCompanySelected(value)}}
                />
                <Dropdown
                    placeholder='Filter by POC'
                    selection
                    search
                    options={pocsLists}
                    value={pocSelected}
                    onChange={(e, { value }) => {setCompanySelected(''); setPocSelected(value)}}
                />
                <Input
                    value={requestDate}
                    onChange={e => setRequestDate(e.currentTarget.value)}
                    icon='calendar alternate outline'
                    iconPosition='left'
                    type='date'
                    min='2022-05-31'
                />
                <Button onClick={handlerFilterByDate}>Filter</Button>
            </div>

            <Tab
                menu={{ secondary: true, pointing: true }}
                panes={panes()}
            />
            {isLoading && <LoaderCustom />}
        </ComponentTemplate>
    )
}