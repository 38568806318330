import { DashboardTemplate } from "../../templates/DashboardTemplate";
import { TabContainer } from "../../components/shared/TabContainer";
import { sysAPI } from "../../apis/SysAPI";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Pagination,
  Container,
  Table,
  Input,
} from "semantic-ui-react";

const DashboardCompanies = () => {
  const history = useHistory();
  const pageSize = 15;
  const [companies, setCompanies] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState({activePage: 1, searchInput: ''});
  const [searchInput, setSearchInput] = useState("");

  const searchBtn_Click = () => {
    setSearch({ activePage: 1, searchInput });
  };

  const pageChange = (e, { activePage }) => {
    setSearch({...search, activePage});
  }
  const apiCall = async () => {
    try {
      const {data} = await sysAPI.get(`/companies-v2`,{params:{
        ...search,
        pageSize
      }})
      //console.log({data});
      setCompanies(data?.data?.data || [])
      setTotalPages(data.data.metadata.pages)
    } catch (error) {
      console.log({error})
    }
}

  useEffect(() => {
    apiCall();

    // eslint-disable-next-line
}, [search]);

  return (
    <DashboardTemplate scope="companies">
      <TabContainer
        title="Companies"
        subtitle="CRUD of Companies"
        icon="building"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "12px",
          }}
        >
          <div>
            <Input
              value={searchInput}
              onChange={(e) => setSearchInput(e.currentTarget.value)}
              icon="search"
              iconPosition="left"
              onKeyPress={(e) =>(e.charCode === 13 || e.keyCode === 13 || e.which === 13) &&searchBtn_Click()}
            />
          </div>
          <div>
            <Button onClick={searchBtn_Click}>Filter</Button>
          </div>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Advisors #</Table.HeaderCell>
              <Table.HeaderCell>POC</Table.HeaderCell>
              <Table.HeaderCell>Billing Advisor</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {companies.map((company, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(`/dashboard/companies/${company._id}`);
                    }}
                  >
                    {company.name}
                  </span>
                </Table.Cell>
                <Table.Cell>{company?.advisorList.length || 0}</Table.Cell>
                <Table.Cell>{company?.poc?.fullName}</Table.Cell>
                <Table.Cell>{company?.billingAdvisor?.fullName}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {totalPages > 1 && (
          <Container fluid textAlign="right">
            <Pagination
              totalPages={totalPages}
              activePage={search.activePage}
              onPageChange={pageChange}
            />
          </Container>
        )}
      </TabContainer>
    </DashboardTemplate>
  );
};

export default DashboardCompanies;
