/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Icon, Input, Popup, Select } from 'semantic-ui-react';
import { setEstateManualInputField } from '../../actions/estate-manual-input.actions';

export const InputItem = ({
    docType,
    label,
    field,
    options,
    nOptions,
    initialValue,
    multiline,
    indent,
    style,
    visible,
    ignoreBorder,
    version = [1],
    rightComponent,
    withoutPages,
    message = '',
    componentWidth = 100,
    dateFormat,
    customChange,
    type,
    addOptions,
    multiple = false,
    min,
    max,
    isRequired,
}) => {
    const dispatch = useDispatch();
    const estateManualInput = useSelector(state => state.estateManualInput);
    const estateManualInputChange = useSelector(state => state.estateManualInputChange);
    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);
    const [theOptions, setTheOptions] = useState([]);
    const input_Change = (field, value) => {
        dispatch(setEstateManualInputField(docType, field, value));
        if (field === 'createsTypeOfTrust') {
            dispatch(setEstateManualInputField(docType, 'whichTrust', ''));
        }
    };

    const isMultipleVal = multiple ? [] : '';

    const dateChange = (field, v) => {
        const value = v.currentTarget.value.replace(/[^0-9/]/g, '');
        if (value.length === 10) {
            const dat = moment(value, 'MM/DD/YYYY');
            console.log(dat.isValid())

            if (!dat.isValid()) {
                v.target.parentNode.classList.add('false');
            } else {
                v.target.parentNode.classList.remove('false');
            }
        }
        dispatch(setEstateManualInputField(docType, field, value));
    }

    const enabled = true;
    const defaultOnChange = () => {}

    if(estateManualInputRequest.estateSnapshotVersion && !version.includes(estateManualInputRequest.estateSnapshotVersion) ) {
        return <></>;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(options || nOptions) {
            // setTheOptions(nOptions || ['', ...options.map(opt => ({ key: opt, value: opt, text: opt }))]);
            const newOptions = nOptions || ['', ...options.map(opt => ({ key: opt, value: opt, text: opt }))];

            if (addOptions && estateManualInput?.[docType]?.[field]) {
                const findOption = newOptions.find(opt => opt.value === estateManualInput?.[docType]?.[field]) || false;
                if (!findOption) {
                    newOptions.push({
                        key: estateManualInput?.[docType]?.[field],
                        value: estateManualInput?.[docType]?.[field],
                        text: estateManualInput?.[docType]?.[field],
                    });
                }
            }

            setTheOptions(newOptions);
        }
    }, []);

    return (
        <div style={{ display: (visible === undefined || visible) ? 'flex' : 'none', flexDirection: 'column', flex: 1, width: `${componentWidth}%`, ...(style || {})}}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                    width: '100%',
                    paddingLeft: `${(indent || 0) * 10}px`,
                    ...(estateManualInputRequest.estateSnapshotVersion === 1 ? style || {} : {}),
                    ...(!ignoreBorder &&  estateManualInputRequest.estateSnapshotVersion === 1 ? { paddingBottom: '10px', borderBottom: '1px solid #f0f0f0' } : {}),
                }}
            >
                <p style={{ display: label ? 'block' : 'none', margin: 0, marginRight: '10px', width: '260px' }}>{label}</p>
                {!options && !nOptions && !multiline && !dateFormat &&
                    <>
                        <Input
                            name={field}
                            value={estateManualInput?.[docType]?.[field] || ''}
                            onChange={enabled
                                ? customChange
                                    ? (e) => {customChange(docType, field, e.currentTarget.value)}
                                    : (e) => {input_Change(field, e.currentTarget.value)}
                                : defaultOnChange
                            }
                            style={{ flex: 1, 
                                border: isRequired && (!estateManualInput?.[docType]?.[field] || estateManualInput?.[docType]?.[field] === '') ? '1px solid red' : estateManualInputChange && docType in estateManualInputChange && field in estateManualInputChange[docType] ? '1px solid rgba(21, 97, 198, 0.5)' : 'none',
                                borderRadius: '0.28571429rem',
                                boxShadow: estateManualInputChange && docType in estateManualInputChange && field in estateManualInputChange[docType] ? 'rgba(21, 97, 198, 0.5) 0px 1px 10px' : 'none' 
                            }}
                            fluid
                            type={type || 'text'}
                            min={min}
                            max={max}
                            onWheel={type === 'number' ? (e) => { e.target.blur()} : undefined }
                        />
                        {estateManualInputChange && docType in estateManualInputChange && field in estateManualInputChange[docType] &&
                        <Popup 
                            content={ estateManualInputChange[docType][field] !== '' ? estateManualInputChange[docType][field] : 'Not Answered'} 
                            trigger={ <Icon name='exclamation circle' size='large' style={{color: 'rgba(21, 97, 198, 0.5)', paddingLeft: '10px'}}/>}
                        />}
                    </>
                }
                {(options || nOptions) &&
                    <>
                        <Select
                            value={estateManualInput?.[docType]?.[field] || isMultipleVal}
                            placeholder={addOptions ? 'Select or type an option...' : 'Select option...'}
                            onChange={enabled
                                ? customChange
                                    ? (e, elem) => {customChange(docType, field, elem.value)}
                                    : (e, elem) => {input_Change(field, elem.value)}
                                : defaultOnChange
                            }
                            options={theOptions || []}
                            style={{ flex: 1,
                                border: isRequired && (!estateManualInput?.[docType]?.[field] || estateManualInput?.[docType]?.[field] === '') ? '1px solid red' : estateManualInputChange && docType in estateManualInputChange && field in estateManualInputChange[docType] ? '1px solid rgba(21, 97, 198, 0.5)' : '1px solid rgba(34,36,38,.15)', 
                                boxShadow: estateManualInputChange && docType in estateManualInputChange && field in estateManualInputChange[docType] ? 'rgba(21, 97, 198, 0.5) 0px 1px 10px' : 'none'
                            }}
                            fluid
                            multiple={multiple}
                            selection={addOptions || multiple}
                            search={addOptions}
                            allowAdditions={addOptions}
                            onAddItem={addOptions ? (e, { value }) => setTheOptions(prev => [...prev, {value, text: value}]) : undefined}
                        />
                        {estateManualInputChange && docType in estateManualInputChange && field in estateManualInputChange[docType] &&
                        <Popup 
                            content={estateManualInputChange[docType][field] !== '' ? (message !== '' ? message : estateManualInputChange[docType][field]) : 'Not Answered'} 
                            trigger={ <Icon name='exclamation circle' size='large' style={{color: 'rgba(21, 97, 198, 0.5)', paddingLeft: '10px'}}/>}
                        />}
                    </>
                }
                {multiline &&
                    <>
                        <Form style={{ flex: 1 }}>
                            <Form.TextArea
                                value={estateManualInput?.[docType]?.[field] || ''}
                                style={{ flex: 1, minHeight: '100px',
                                    border: isRequired && (!estateManualInput?.[docType]?.[field] || estateManualInput?.[docType]?.[field] === '') ? '1px solid red' : estateManualInputChange && docType in estateManualInputChange && field in estateManualInputChange[docType] ? '1px solid rgba(21, 97, 198, 0.5)' : 'border: 1px solid rgba(34,36,38,.15)',
                                    borderRadius: '0.28571429rem',
                                    boxShadow: estateManualInputChange && docType in estateManualInputChange && field in estateManualInputChange[docType] ? 'rgba(21, 97, 198, 0.5) 0px 1px 10px' : 'none' 
                                }}
                                onChange={enabled
                                    ? customChange
                                        ? (e, elem) => {customChange(docType, field, elem.value)}
                                        : (e, elem) => {input_Change(field, elem.value)}
                                    : defaultOnChange
                                }
                                fluid
                            ></Form.TextArea>
                        </Form>
                        {estateManualInputChange && docType in estateManualInputChange && field in estateManualInputChange[docType] &&
                        <Popup 
                            content={estateManualInputChange[docType][field] !== '' ? estateManualInputChange[docType][field] : 'Not Answered'} 
                            trigger={ <Icon name='exclamation circle' size='large' style={{color: 'rgba(21, 97, 198, 0.5)', paddingLeft: '10px'}}/>}
                        />}
                    </>
                }
                {dateFormat &&
                    <>
                    <Input
                        name={field}
                        value={estateManualInput?.[docType]?.[field] || ''}
                        onChange={enabled && (e => dateChange(field, e)) || defaultOnChange}
                        placeholder="mm/dd/aaaa"
                        pattern="\d{2}/\d{2}/\d{4}"
                        style={{ flex: 1,
                            border: isRequired && (!estateManualInput?.[docType]?.[field] || estateManualInput?.[docType]?.[field] === '') ? '1px solid red' : estateManualInputChange && docType in estateManualInputChange && field in estateManualInputChange[docType] ? '1px solid rgba(21, 97, 198, 0.5)' : 'none',
                            borderRadius: '0.28571429rem',
                            boxShadow: estateManualInputChange && docType in estateManualInputChange && field in estateManualInputChange[docType] ? 'rgba(21, 97, 198, 0.5) 0px 1px 10px' : 'none',
                        }}
                        className="dateValidation"
                        maxLength={10}
                        fluid
                    />
                    {estateManualInputChange && docType in estateManualInputChange && field in estateManualInputChange[docType] &&
                    <Popup 
                        content={ estateManualInputChange[docType][field] !== '' ? estateManualInputChange[docType][field] : 'Not Answered'} 
                        trigger={ <Icon name='exclamation circle' size='large' style={{color: 'rgba(21, 97, 198, 0.5)', paddingLeft: '10px'}}/>}
                    />}
                </>}
                {rightComponent && rightComponent()}
            </div>
            {!withoutPages && [2, 3].includes(estateManualInputRequest.estateSnapshotVersion) && 
                <div style={{
                    display: (visible === undefined || visible) ? 'flex' : 'none',
                    justifyContent: 'right',
                    alignItems: 'center',
                    width: '100%',
                    paddingLeft: `${(indent || 0) * 10}px`,
                    ...(style || {}),
                }}>
                    <small style={{ margin: 0, marginRight: '10px'}}>Page #</small>
                    <Input
                        name={`${field}__page`}
                        value={estateManualInput?.[docType]?.[`${field}__page`] || ''}
                        // eslint-disable-next-line
                        onChange={enabled && (e => input_Change(`${field}__page`, e.currentTarget.value)) || ''}
                        style={{width: '80px', fontSize: '11px', padding:' 0.5em 1em !important',
                            border: isRequired && (!estateManualInput?.[docType]?.[field] || estateManualInput?.[docType]?.[field] === '') ? '1px solid red' : estateManualInputChange && docType in estateManualInputChange && `${field}__page` in estateManualInputChange[docType] ? '1px solid rgba(21, 97, 198, 0.5)' : 'none',
                            borderRadius: '0.28571429rem',
                            boxShadow: estateManualInputChange && docType in estateManualInputChange && `${field}__page` in estateManualInputChange[docType] ? 'rgba(21, 97, 198, 0.5) 0px 1px 10px' : 'none' 
                        }}
                    />
                    <small style={{ margin: 0, marginRight: '10px',  marginLeft: '10px'}}>Paragraph #</small>
                    <Input
                        name={`${field}__paragraph`}
                        value={estateManualInput?.[docType]?.[`${field}__paragraph`] || ''}
                        // eslint-disable-next-line
                        onChange={enabled && (e => input_Change(`${field}__paragraph`, e.currentTarget.value)) || ''}
                        style={{width: '80px', fontSize: '11px', padding:' 0.5em 1em !important',
                        border: isRequired && (!estateManualInput?.[docType]?.[field] || estateManualInput?.[docType]?.[field] === '') ? '1px solid red' : estateManualInputChange && docType in estateManualInputChange && `${field}__paragraph` in estateManualInputChange[docType] ? '1px solid rgba(21, 97, 198, 0.5)' : 'none',
                        borderRadius: '0.28571429rem',
                        boxShadow: estateManualInputChange && docType in estateManualInputChange && `${field}__paragraph` in estateManualInputChange[docType] ? 'rgba(21, 97, 198, 0.5) 0px 1px 10px' : 'none' 
                        }}
                    />
                </div>
            }
            <div style={{ marginBottom: '10px', ...(!ignoreBorder ? { paddingBottom: '10px', borderBottom: '1px solid #f0f0f0' } : {}) }} ></div>
        </div>
    )
}

export const DocInputItem = (docType) => {
    return ({ label, field, options, initialValue, multiline, indent }) => {
        return <InputItem docType={docType} label={label} field={field} options={options} initialValue={initialValue} multiline={multiline} indent={indent} />
    }
}