import { Switch, Route, /* Redirect */ } from "react-router-dom"
import { DashboardAdvisorsView } from "../views/DashboardAdvisorsView"
import { AdvisorProfileView } from '../views/AdvisorProfileView';
import { DashboardSalesView } from "../views/DashboardSalesView";
import { ProtectedViewTemplate } from "../templates/ProtectedViewTemplate";
import { DashboardMetricsView } from "../views/DashboardMetricsView";
import { DashboardManualInputView } from "../views/DashboardManualInputView";
import { InitialDashboardRouteDecider } from "../components/shared/InitialDashboardRouteDecider";
import { AdvisorSalesProfileView } from "../views/AdvisorSalesProfileView";
import { ClientEstateFilesViews } from "../views/ClientEstateFilesView";
import { EstateManualInput } from "../views/EstateManualInput";
import { AdminView } from "../views/AdminView";
import { DashboardClientsView } from "../views/DashboardClientsView";
import { DashboardResourcesView } from "../views/DashboardResourcesView";
import { QuickbooksView } from "../views/QuickbooksView";
import { QuickbooksCallbackView } from "../views/QuickbooksCallbackView";
import { QuickbooksDisconnectedView } from "../views/QuickbooksDisconnectedView";
import DashboardCompanies from "../views/Company/DashboardCompanies";
import CompanyProfile from "../views/Company/CompanyProfile";
import { InsuranceSnapshot } from "../views/InsuranceSnapshot";

export const DashboardRouter = () => {
    return (
        <ProtectedViewTemplate>
            <Switch>
                <Route exact path='/dashboard' component={InitialDashboardRouteDecider} />
                <Route exact path='/dashboard/advisors' component={DashboardAdvisorsView} />
                <Route exact path='/dashboard/advisors/:advisorId' component={AdvisorProfileView} />
                <Route exact path='/dashboard/sales' component={DashboardSalesView} />
                <Route exact path='/dashboard/sales/:advisorId' component={AdvisorSalesProfileView} />
                <Route exact path='/dashboard/metrics' component={DashboardMetricsView} />
                <Route exact path='/dashboard/manual-input' component={DashboardManualInputView} />
                <Route exact path='/dashboard/estate-manual-input/:clientId/:assetIndex/files' component={ClientEstateFilesViews} />
                <Route exact path='/dashboard/estate-manual-input/:clientId/:assetIndex' component={EstateManualInput} />
                <Route exact path='/dashboard/admin' component={AdminView} />
                <Route exact path='/dashboard/quickbooks' component={QuickbooksView} />
                <Route exact path='/dashboard/quickbooks/callback' component={QuickbooksCallbackView} />
                <Route exact path='/dashboard/quickbooks/disconnect' component={QuickbooksDisconnectedView} />
                <Route exact path='/dashboard/clients' component={DashboardClientsView} />
                <Route exact path='/dashboard/resources' component={DashboardResourcesView} />

                <Route exact path='/dashboard/companies' component={DashboardCompanies} />
                <Route exact path='/dashboard/companies/:companyId' component={CompanyProfile} />
                <Route exact path='/dashboard/insurance-snapshot/:clientId/:areaAsset' component={InsuranceSnapshot} />
            </Switch>
        </ProtectedViewTemplate>
    )
}