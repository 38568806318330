/* eslint-disable no-mixed-operators */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import { Button, Checkbox, Icon, Input, Segment, Select, TextArea } from 'semantic-ui-react';
import { addEstateManualInputAsset, removeEstateManualInputAsset, setEstateManualInputField, removeEstateManualInputField } from '../../actions/estate-manual-input.actions';
import { v4 as uuid } from 'uuid';
import { InputItem } from '../shared/EstateInputItem';
import { InsuranceCustomFields } from "./InsuranceCustomFields";

export const UmbrellaForm = ({ docId, area, insuranceProviders, assets }) => {
    const docType = `${docId}`;
    // const dispatch = useDispatch();
    // const client = useSelector(state => state.currentClient);
    const estateManualInput = useSelector(state => state.estateManualInput);

    const imagen = () => (
        <img
            style={{ marginLeft: '10px', maxHeight: '64px', height: '100%', width: 'auto', maxWidth:'200px' }}
            src={insuranceProviders.find(itm => itm.showName === estateManualInput?.[docType]?.insuranceProvider)?.url || ''}
            alt={estateManualInput?.[`${docType}___insuranceProvider`] || ''}
        />
    );

    return (
        <>
            <div style={{ marginBottom: '20px' }}>
                <h4>Policy Summary</h4>
                <InputItem
                    docType={docType}
                    label='Select asset'
                    field='insuranceAsset'
                    nOptions={assets.filter(itm => itm.type.toLowerCase() === area).map(itm => ({ key: itm._id, value: itm._id, text: itm.title }))}
                    version={[1,2,3]}
                />
                <InputItem
                    docType={docType}
                    label='Insurance provider'
                    field='insuranceProvider'
                    options={insuranceProviders.map(itm => (itm.showName))}
                    version={[1,2,3]}
                    addOptions
                    rightComponent={estateManualInput?.[docType]?.insuranceProvider && imagen}
                />
                <InputItem docType={docType} label='Name Insured' field='nameInsured' version={[1,2,3]} withoutPages />
                <InputItem docType={docType} label='Policy number' field='policyNumber' version={[1,2,3]} withoutPages />
                {/* <InputItem docType={docType} label='Policy period' field='policyPeriod' version={[1,2,3]} withoutPages dateFormat /> */}
                <div style={{ padding: '10px 0', marginBottom: '10px', borderBottom: '1px solid #e8e8e8', display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '8px'}}>
                    <div style={{width: '260px'}}>Policy period</div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1, columnGap: '8px'}}>
                        <InputItem docType={docType} label='' field='policyPeriodStart' version={[1,2,3]} withoutPages ignoreBorder dateFormat />
                        <InputItem docType={docType} label='' field='policyPeriodEnd' version={[1,2,3]} withoutPages ignoreBorder dateFormat />
                    </div>
                </div>
                <InputItem docType={docType} label='Mailing Address' field='mailingAddress' version={[1,2,3]} withoutPages />
                <InputItem docType={docType} label='Registered/Total Umbrella Policies' field='totalUmbrellaPolicies' version={[1,2,3]} withoutPages />
                <InputItem docType={docType} label='Premium' field='premium' version={[1,2,3]} withoutPages />
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Umbrella Policy Limit</h4>
                <InsuranceCustomFields docId={docType} area={area} category="umbrellaPolicyLimit" />
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Underlying Limits</h4>
                <InsuranceCustomFields docId={docType} area={area} category="underlyingLimits" />
            </div>
        </>
    )
}