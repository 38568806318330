// eslint-disable-next-line
export default [
    {
        section: 'Form 1040',
        fields: [
            {
                name: 'wages',
                text: 'Wages',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'taxExemptInterest',
                text: 'Tax-exempt interest',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'taxInterest',
                text: 'Taxable interest',
                line: 'Line 2b',
                type: 'number',
            },
            {
                name: 'qualifiedDividends',
                text: 'Qualified dividends',
                line: 'Line 3a',
                type: 'number',
            },
            {
                name: 'totalDividends',
                text: 'Ordinary dividends',
                line: 'Line 3b',
                type: 'number',
            },
            {
                name: 'iraDistribution',
                text: 'IRA distributions',
                line: 'Line 4a',
                type: 'number',
            },
            {
                name: 'taxableIRA',
                text: 'IRA distributions taxable amount',
                line: 'Line 4b',
                type: 'number',
            },
            {
                name: 'pension',
                text: 'Pensions and annuities',
                line: 'Line 4c',
                type: 'number',
            },
            {
                name: 'taxablePension',
                text: 'Pensions and annuities taxable amount',
                line: 'Line 4d',
                type: 'number',
            },
            {
                name: 'socialSecurityBenefits',
                text: 'Social security benefits',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'socialSecurity',
                text: 'Social security benefits taxable amount',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'capitalGainOrLoss',
                text: 'Capital gain or (loss)',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'totalIncome',
                text: 'Total income',
                line: 'Line 7b',
                type: 'number',
            },
            {
                name: 'adjustedIncome',
                text: 'Adjustments to income',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'adjustedGrossIncome',
                text: 'Adjusted gross income',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'standardDeductions',
                text: 'Standard deductions or itemized deductions',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'qbIncome',
                text: 'Qualified business income deductions',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'taxableIncome',
                text: 'Taxable income',
                line: 'Line 11b',
                type: 'number',
            },
            {
                name: 'taxes',
                text: 'Taxes',
                line: 'Line 12a',
                type: 'number',
            },
            // {
            //     name: 'qualifyingDependentCreditClaimed',
            //     text: 'Qualifying dependent credit claimed',
            //     line: 'Line 19',
            //     type: 'number',
            // },
            {
                name: 'totalTax',
                text: 'Total tax',
                line: 'Line 16',
                type: 'number',
            },
            // {
            //     name: 'earnedIncomeCredit',
            //     text: 'Earned Income Credit',
            //     line: 'Line 27',
            //     type: 'number',
            // },
            // {
            //     name: 'americanOpportunityCreditTaken',
            //     text: 'American opportunity credit taken',
            //     line: 'Line 29',
            //     type: 'number',
            // },
        ],
    },
    {
        section: 'Schedule 1',
        fields: [
            {
                name: 'alimonyReceived',
                text: 'Alimony received',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'businessIncome',
                text: 'Business income/loss',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'rentalRealEstate',
                text: 'Rental real estate, royalties, partnerships, S corporations, trusts, etc',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'healthSavings',
                text: 'Health savings',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'selfEmployedSEP',
                text: 'Self employed SEP',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'alimonyPaid',
                text: 'Alimony paid',
                line: 'Line 18a',
                type: 'number',
            },
            {
                name: 'iraDeduction',
                text: 'IRA deduction',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'studentLoanInterestDeduction',
                text: 'Student loan interest deduction',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'adjustmentToIncome',
                text: 'Adjustments to income',
                line: 'Line 22',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 3',
        fields: [
            {
                name: 'creditForChildCareTF',
                text: 'Credit for child/dependent care?',
                line: 'Line 2',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'creditForChildCare',
                text: 'Credit for child/dependent care',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'educationCredits',
                text: 'Education credits',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'energyCredits',
                text: 'Residential energy credits',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'estimatedTax',
                text: 'Estimated tax',
                line: 'Line 8',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule A',
        fields: [
            {
                name: 'medicalExpenses',
                text: 'Medical and dental expenses',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'medicalExpenses2',
                text: 'Multiply line 2 by 7.5%',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'medicalExpenses3',
                text: 'Line 3 minus line 1',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5d',
                text: 'Taxes you paid 5d',
                line: 'Line 5d',
                type: 'number',
            },
            {
                name: 'taxesYouPaid',
                text: 'Deductions allowed for taxes paid',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'investmentInterest',
                text: 'Investment interest',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'interestYouPaid',
                text: 'Interest you paid (total)',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'giftsByCash',
                text: 'Gifts by cash or check',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'giftsByLand',
                text: 'Other than by cash or check',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'giftsToCharityTF',
                text: 'Gifts to charity?',
                line: 'Line 14',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'giftsToCharity',
                text: 'Gifts to charity',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'casualtyLosses',
                text: 'Casualty and theft losses',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'otherItemizedDeductions',
                text: 'Other itemized deductions',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'summationsItemizedDeductions',
                text: 'Total itemized deductions',
                line: 'Line 17',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule C',
        fields: [
            {
                name: 'contractLabor',
                text: 'Contract labor',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'employeeBenefit',
                text: 'Employee benefit',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'wagesLessEmployeeCredits',
                text: 'Wages less employee credits',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'expensesForHomeBusinessTF',
                text: 'Expenses for business use of your home?',
                line: 'Line 30',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'expensesForHomeBusiness',
                text: 'Expenses for business use of your home',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'netProfitOrLoss',
                text: 'Net profit or (loss) from business',
                line: 'Line 31',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule D',
        fields: [
            {
                name: 'netShortTermCapitalGains',
                text: 'Net short-term capital gain or (loss)',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'netLongTermCapitalGains',
                text: 'Net long-term capital gain (or loss)',
                line: 'Line 15',
                type: 'number',
            },
        ],
    },
    {
        section: 'Other Ones',
        fields: [
            {
                name: 'hasScheduleSE',
                text: 'Has Schedule SE?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'hasScheduleE',
                text: 'Has Schedule E?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'hasForm8863',
                text: 'Has Form 8863?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm1120',
                text: 'Has Form 1120?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm1120S',
                text: 'Has Form 1120S?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm8839',
                text: 'Has Form 8839?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm6251',
                text: 'Has Form 6251?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm5695',
                text: 'Has Form 5695?',
                type: 'string',
                options: ['Yes', 'No'],
            },
        ],
    },
]