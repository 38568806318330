import { DashboardTemplate } from "../templates/DashboardTemplate"
import { TabContainer } from '../components/shared/TabContainer'
import { Button, Table, Input, Container, Pagination, Tab, /* Select, */ Dropdown, Icon } from 'semantic-ui-react';
import { useState, useEffect } from "react";
import { sysAPI } from "../apis/SysAPI";
import { Link, useHistory } from "react-router-dom";
import { paymentAPI } from "../apis/PaymentAPI";
import moment from "moment";
import { UpdateRenewalDateModal } from "../components/modal/UpdateRenewalDateModal";

const FindAdvisorTab = () => {
    const history = useHistory();

    const pageSize = 15;
    const [activePage, setActivePage] = useState(1);
    const [advisorsList, setAdvisorsList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        sysAPI.get(`/advisors?page=${activePage}&size=${pageSize}`).then(response => {
            const { advisorsList, totalPages } = response.data;
            setAdvisorsList(advisorsList);
            setTotalPages(totalPages);
        }).catch(err => {

        });

    // eslint-disable-next-line
    }, []);

    const pageChange = (e, { activePage }) => {
        setActivePage(activePage);
        sysAPI.get(`/advisors?page=${activePage}&size=${pageSize}`).then(response => {
            const { advisorsList, totalPages } = response.data;
            setAdvisorsList(advisorsList);
            setTotalPages(totalPages);
        }).catch(err => {

        });
    };

    const searchBtn_Click = () => {
        sysAPI.get(`/advisors?page=${activePage}&size=${pageSize}&query=${searchInput}`).then(response => {
            const { advisorsList, totalPages } = response.data;
            setAdvisorsList(advisorsList);
            setTotalPages(totalPages);
        }).catch(err => {

        });
    };

    const clearFilterBtn_Click = () => {
        setSearchInput('');
        sysAPI.get(`/advisors?page=${activePage}&size=${pageSize}`).then(response => {
            const { advisorsList, totalPages } = response.data;
            setAdvisorsList(advisorsList);
            setTotalPages(totalPages);
        }).catch(err => {

        });
    };

    const advisorRow_Click = (advisor) => {
        return () => {
            history.push(`/dashboard/sales/${advisor._id}`);
        }
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Input
                    value={searchInput}
                    onChange={e => setSearchInput(e.currentTarget.value)}
                    icon='search'
                    action={{ content: 'Search', onClick: searchBtn_Click }}
                    iconPosition='left'
                    style={{ marginRight: '10px', width: '500px' }}
                    onKeyPress={e => (e.charCode === 13 || e.keyCode === 13 || e.which === 13) && searchBtn_Click()}
                />
                <Button onClick={clearFilterBtn_Click}>Clear filter</Button>
            </div>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Full Name</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Company</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {advisorsList?.map(advisor => (
                        <Table.Row key={advisor._id} onClick={advisorRow_Click(advisor)}>
                            <Table.Cell>{advisor.fullName}</Table.Cell>
                            <Table.Cell>{advisor.email}</Table.Cell>
                            <Table.Cell>{advisor.nameCompany}</Table.Cell>
                            <Table.Cell>{advisor.status}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Container fluid textAlign='right'>
                <Pagination totalPages={totalPages} activePage={activePage} onPageChange={pageChange} />
            </Container>
        </>
    )
}

const RenewalsTab = () => {
    //const history = useHistory();

    const pageSize = 15;
    const [activePage, setActivePage] = useState(1);
    const [advisorsList, setAdvisorsList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [searchInput, setSearchInput] = useState('');

    const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
    const [sorting, setSorting] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [editRenewal, setEditRenewal] = useState(null);

    useEffect(() => {
        sysAPI.get(`/advisors/renewals?page=${activePage}&size=${pageSize}`).then(response => {
            const { advisorsList, totalPages } = response.data;
            setAdvisorsList(advisorsList);
            setTotalPages(totalPages);
        }).catch(err => {

        });

    // eslint-disable-next-line
    }, []);

    const pageChange = (e, { activePage }) => {
        setActivePage(activePage);

        const query = {
            page: activePage,
            size: pageSize,
            search: searchInput,
        };

        if (['renewal_date_asc', 'renewal_date_desc'].includes(sorting)) {
            query.sort = sorting;
        }

        if (startDate) query.startDate = startDate;
        if (endDate) query.endDate = endDate;

        const queryString = Object.keys(query).map(q => `${q}=${query[q]}`).join('&');

        sysAPI.get(`/advisors/renewals?${queryString}`).then(response => {
            const { advisorsList, totalPages } = response.data;
            setAdvisorsList(advisorsList);
            setTotalPages(totalPages);
        }).catch(err => {

        });
    };

    const searchBtn_Click = () => {
        setActivePage(1);

        const query = {
            page: 1,
            size: pageSize,
            search: searchInput,
        };

        if (['renewal_date_asc', 'renewal_date_desc'].includes(sorting)) {
            query.sort = sorting;
        }

        if (startDate) query.startDate = startDate;
        if (endDate) query.endDate = endDate;

        const queryString = Object.keys(query).map(q => `${q}=${query[q]}`).join('&');

        sysAPI.get(`/advisors/renewals?${queryString}`).then(response => {
            const { advisorsList, totalPages } = response.data;
            setAdvisorsList(advisorsList);
            setTotalPages(totalPages);
        }).catch(err => {

        });
    };

    const clearFilterBtn_Click = () => {
        setSearchInput('');
        clearDatesFilterBtn_Click();
        setSorting('');
        setActivePage(1);
        sysAPI.get(`/advisors/renewals?page=1&size=${pageSize}`).then(response => {
            const { advisorsList, totalPages } = response.data;
            setAdvisorsList(advisorsList);
            setTotalPages(totalPages);
        }).catch(err => {

        });
    };

    const reloadPageAfterRenewalDateUpdate = () => {
        const query = {
            page: activePage,
            size: pageSize,
            search: searchInput,
        };

        if (['renewal_date_asc', 'renewal_date_desc'].includes(sorting)) {
            query.sort = sorting;
        }

        if (startDate) query.startDate = startDate;
        if (endDate) query.endDate = endDate;

        const queryString = Object.keys(query).map(q => `${q}=${query[q]}`).join('&');

        sysAPI.get(`/advisors/renewals?${queryString}`).then(response => {
            const { advisorsList, totalPages } = response.data;
            setAdvisorsList(advisorsList);
            setTotalPages(totalPages);
        }).catch(err => {

        });
    };

    const clearDatesFilterBtn_Click = () => {
        setEndDate('');
        setStartDate('');
    };

    const renewalItem_Click = (advisor) => {
        return () => {
            setEditRenewal(advisor);
        }
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Input
                    value={searchInput}
                    onChange={e => setSearchInput(e.currentTarget.value)}
                    icon='search'
                    // action={{ content: 'Search', onClick: searchBtn_Click }}
                    iconPosition='left'
                    placeholder='Search...'
                    style={{ marginRight: '10px', flex: 1 }}
                    onKeyPress={e => (e.charCode === 13 || e.keyCode === 13 || e.which === 13) && searchBtn_Click()}
                />
                <Dropdown
                    placeholder="Select sorting option"
                    selection
                    options={[
                        { text: '(No Sorting)', value: 'no_sorting' },
                        { text: 'Sort by renewal date (asc)', value: 'renewal_date_asc' },
                        { text: 'Sort by renewal date (desc)', value: 'renewal_date_desc' },
                    ]}
                    style={{ marginRight: '10px' }}
                    onChange={(e, { value }) => setSorting(value)}
                    value={sorting}
                />
                <Button style={{ marginRight: '10px' }} icon basic color="blue" onClick={() => setShowAdditionalFilters(!showAdditionalFilters)}>
                    Additional Filters
                    <Icon name={`chevron ${showAdditionalFilters ? 'up' : 'down'}`} />
                </Button>
                <Button onClick={searchBtn_Click}>Search</Button>
                <Button onClick={clearFilterBtn_Click}>Clear filter</Button>
            </div>
            <div style={{ display: showAdditionalFilters ? 'flex' : 'none', marginTop: '10px' }}>
                <Input
                    label='from'
                    type="date"
                    style={{ marginRight: '10px' }}
                    onChange={(e, { value }) => setStartDate(value)}
                    value={startDate}
                />
                <Input
                    label='to'
                    type="date"
                    style={{ marginRight: '10px' }}
                    onChange={(e, { value }) => setEndDate(value)}
                    value={endDate}
                />
                <Button basic color="orange" onClick={clearDatesFilterBtn_Click}>Clear dates</Button>
            </div>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Full Name</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Company</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Creation</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Renewal</Table.HeaderCell>
                        <Table.HeaderCell>Time left</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {advisorsList?.map(advisor => {
                        const creationDate = moment(advisor.creationDate).format('MM-DD-YYYY');
                        const renewalDate = advisor.renewalDate
                            ? moment(advisor.renewalDate).format('MM-DD-YYYY')
                            : 'N/A';
                        return(
                            <Table.Row key={advisor._id}>
                                <Table.Cell>{advisor.fullName}</Table.Cell>
                                <Table.Cell>{advisor.email}</Table.Cell>
                                <Table.Cell>{advisor.nameCompany}</Table.Cell>
                                <Table.Cell textAlign="right">{creationDate}</Table.Cell>
                                <Table.Cell textAlign="right">
                                    <span style={{ cursor: 'pointer' }} onClick={renewalItem_Click(advisor)}>
                                        {renewalDate}
                                    </span>
                                </Table.Cell>
                                <Table.Cell
                                    style={{
                                        // eslint-disable-next-line
                                        color: advisor.renewalTime && (
                                            advisor.renewalTime.months <= 1
                                                ? 'red'
                                                : advisor.renewalTime.months <= 2
                                                    ? 'orange'
                                                    : 'black'
                                        // eslint-disable-next-line
                                        ) || 'black'

                                    }}
                                >
                                    {advisor.renewalTime && 
                                        <span>
                                            {advisor.renewalTime.months} months, {advisor.renewalTime.days} days
                                        </span>
                                    }
                                    {!advisor.renewalTime && <span>N/A</span>}
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
            <Container fluid textAlign='right'>
                <Pagination totalPages={totalPages} activePage={activePage} onPageChange={pageChange} />
            </Container>
            {editRenewal !== null &&
                <UpdateRenewalDateModal
                    advisor={editRenewal}
                    setEditRenewal={setEditRenewal}
                    updatePage={reloadPageAfterRenewalDateUpdate}
                />
            }
        </>
    )
}

export const AllInvoicesTab = () => {
    return (
        <>
        </>
    )
}

export const AllPaymentsTab = () => {
    const [payments, setPayments] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));

    const fetchPayments = () => {
        paymentAPI.get('/invoice-payment', {
            params: {
                startDate,
                endDate,
            },
        }).then(response => {
            setPayments(response.data.invoicePayments.map(payment => ({
                ...payment,
                fee: +(Math.round((0.029 * payment.amount + 0.30) * 100) / 100).toFixed(2),
            })));
        });
    }

    useEffect(() => {
        fetchPayments();

    // eslint-disable-next-line
    }, []);
    
    const applyBtn_Click = () => {
        fetchPayments();
    }

    const successPayments = payments.filter(payment => payment.status === 'succeeded');

    return (
        <>
            <Container fluid>
                <span style={{ margin: '0 5px' }}>From</span>
                <Input type='date' value={startDate} onChange={e => setStartDate(e.currentTarget.value)} />
                <span style={{ margin: '0 5px' }}>to</span>
                <Input type='date' value={endDate} onChange={e => setEndDate(e.currentTarget.value)} />
                <Button style={{ marginLeft: '10px' }} onClick={applyBtn_Click}>Apply</Button>
            </Container>
            <Container fluid style={{ marginTop: '5px' }}>
                <Button color='green' style={{ fontSize: '11px' }} onClick={() => { setStartDate(moment().startOf('month').format('YYYY-MM-DD')); setEndDate(moment().endOf('month').format('YYYY-MM-DD')) }}>This month</Button>
                <Button color='purple' style={{ fontSize: '11px' }} onClick={() => { setStartDate(moment().startOf('year').format('YYYY-MM-DD')); setEndDate(moment().endOf('year').format('YYYY-MM-DD')) }}>This year</Button>
                <Button color='grey' style={{ fontSize: '11px' }} onClick={() => { setStartDate(moment('1970-01-01').format('YYYY-MM-DD')); setEndDate(moment('9999-12-31').format('YYYY-MM-DD')) }}>From the beginnings of time till the end of space</Button>
            </Container>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Full Name</Table.HeaderCell>
                        <Table.HeaderCell>Company</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Paid Units</Table.HeaderCell>
                        <Table.HeaderCell>Paid</Table.HeaderCell>
                        <Table.HeaderCell>Stripe Fee</Table.HeaderCell>
                        <Table.HeaderCell>Net Amount</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {payments.map(payment => <Table.Row style={{ color: payment.status === 'failed' ? 'red' : 'black' }}>
                        <Table.Cell>{moment(payment.date).format('MM/DD/YYYY [at] HH:mm')}</Table.Cell>
                        <Table.Cell>
                            <Link style={{ color: 'black', textDecoration: 'underline' }} to={`/dashboard/sales/${payment.advisor._id}`}>{payment.advisor.fullName}</Link>
                        </Table.Cell>
                        <Table.Cell>{payment.advisor.nameCompany}</Table.Cell>
                        <Table.Cell>{payment.advisor.email}</Table.Cell>
                        <Table.Cell>{payment.paidUnits} / 12</Table.Cell>
                        <Table.Cell>${payment.amount}</Table.Cell>
                        <Table.Cell>${payment.fee}</Table.Cell>
                        <Table.Cell>${payment.amount - payment.fee}</Table.Cell>
                    </Table.Row>)}
                </Table.Body>
            </Table>
            <h3 style={{ margin: 0 }}>
                Total
                <span style={{ padding: '0 10px' }}>&gt;</span>
                ${successPayments.reduce((total, payment) => total + payment.amount, 0).toFixed(2)}
                <span style={{ padding: '0 10px' }}>-</span>
                ${successPayments.reduce((total, payment) => total + payment.fee, 0).toFixed(2)} fees
                <span style={{ padding: '0 10px' }}>=</span>
                ${successPayments.reduce((total, payment) => total + (payment.amount - payment.fee), 0).toFixed(2)}
            </h3>
        </>
    )
}

export const DashboardSalesView = () => {
    const tabPanes = [
        { menuItem: { key: 'findAdvisor', icon: 'user', content: 'Find Advisor' }, render: () => <FindAdvisorTab /> },
        // { menuItem: { key: 'invoices', icon: 'book', content: 'Invoices' }, render: () => <AllInvoicesTab /> },
        { menuItem: { key: 'payments', icon: 'payment', content: 'Payments' }, render: () => <AllPaymentsTab /> },
        { menuItem: { key: 'renewals', icon: 'redo', content: 'Renewals' }, render: () => <RenewalsTab /> },
    ];

    return (
        <DashboardTemplate scope='sales'>
            <TabContainer
                title='Sales Management'
                subtitle='View and update licenses, invoices, payments for the advisors'
                icon='chart line'
            >
                <Tab menu={{ fluid: true, vertical: true }} panes={tabPanes} style={{ marginTop: '20px' }} />
            </TabContainer>
        </DashboardTemplate>
    )
}