/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { Link } from 'react-router-dom';
import { Modal, Header, Dropdown, Input, Button, Form, /* Message */ } from 'semantic-ui-react';
import { sysAPI } from '../../apis/SysAPI';
import { manualInputFields } from '../../data/manual-input-fields';
import qs from 'query-string';
import fulltax_in_depth from '../../data/manual-input-fields/fulltax_in_depth';
import { PropertiesModal } from './PopertiesModal';
import { fetchCurrentClient } from '../../actions/client.actions';

const ALL_AREAS = {
    'fulltax': 'Tax Planning',
    // 'estate': 'Estate Planning',
    'auto': 'Auto',
    //'crossborder': 'Cross Border',
    // 'disability': 'Disability',
    'home': 'Home',
    // 'medical': 'Medical',
    // 'identity': 'Identity',
    // 'student': 'Student Loan',
    // 'expense': 'Expense',
    // 'ltc': 'LTC',
    // 'mortgage': 'Mortgage',
    // 'life': 'Life Planning',
    // 'divorce': 'Divorce Planning',
    // 'property': 'Property',
    // 'creditor': 'Creditor',
    // 'elder': 'Elder care',
};

export const ManualInputField = ({ text, section, line, options, onInputChange, value, providers }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid silver', padding: '15px 0' }}>
            <div style={{ marginRight: '20px' }}>
                <div style={{ fontSize: '14px' }}>{text}</div>
                {line && <div style={{ fontSize: '14px', color: 'silver' }}>{section}, {line}</div>}
            </div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                {(options && options === 'provider') &&
                    <Dropdown
                        placeholder='Insurance provider...'
                        value={value}
                        selection
                        search
                        options={providers.map(itm => ({key: itm.showName, value: itm.showName, text: itm.showName}))}
                        style={{ maxWidth: '200px', minWidth: 'unset', flex: '1 1 auto'}}
                        onChange={(_, val) => onInputChange(val.value)}
                    />
                }
                {(options && options !== 'provider') &&
                    <Form.Group style={{ display: 'flex' }}>
                        {options.map(opt =>
                            <Form.Field>
                                <Form.Radio
                                    value={opt}
                                    label={opt}
                                    onChange={e => onInputChange(opt)}
                                    style={{ marginRight: '10px' }}
                                    checked={value === opt}
                                />
                            </Form.Field>
                        )}
                    </Form.Group>
                }
                {!options &&
                    <Input
                        value={value}
                        style={{ width: '200px' }}
                        placeholder='0'
                        onChange={e => onInputChange(e.currentTarget.value)}
                    />
                }
            </div>
        </div>
    )
}

export const ManualInputModal = ({ advisor, setAdvisor }) => {
    const [clients, setClients] = useState(null);
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedAreaId, setSelectedAreaId] = useState('');
    const [indexId, setIndexId] = useState('');
    const [questionnaire, setQuestionnaire] = useState({});
    const [taxYear, setTaxYear] = useState('');
    const [taxYearOptions, setTaxYearOptions] = useState([]);
    // eslint-disable-next-line
    const [estateManualInput, setEstateManualInput] = useState(null);
    const [showPropertiesModal, setShowPropertiesModal] = useState(false);
    const [allAssets, setAllAssets] = useState([]);
    const [allProviders, setAllProviders] = useState([]);
    const [assets, setAssets] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState('');
    const [fetchingProperties, setFetchingProperties] = useState(false);

    // eslint-disable-next-line
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();

    const sortProperties = (properties) => {
        const home = [];
        const auto = [];
        if (properties?.data?.home?.length > 0) {
            properties?.data?.home?.forEach(itm => {
                home.push({
                    key: itm._id,
                    text: itm.title,
                    value: itm.index,
                });
            });
        }
        if (properties?.data?.auto?.length > 0) {
            properties?.data?.auto?.forEach(itm => {
                auto.push({
                    key: itm._id,
                    text: itm.title,
                    value: itm.index,
                });
            });
        }
        setAssets(selectedAreaId === 'home' ? home : auto);
        setFetchingProperties(false);
    }

    const getProperties = (id) => {
        setSelectedAsset('');
        setFetchingProperties(true);
        sysAPI.get(`/insurance-snapshot/assets/${id}`)
            .then(response => {
                setAllAssets(response);
                sortProperties(response);
            })
    };

    const getAllProviders = () => {
        sysAPI.get('/insurance-providers/')
            .then(response => {
                setAllProviders(response.data);
            });
    };

    useEffect(() => {
        const { clientId, area } = qs.parse(window.location.search);
        // console.log({clients});

        if (area) {
            setSelectedAreaId(area);
        }

        sysAPI.get(`/advisor/${advisor._id}/clients`).then(response => {
            setClients(response.data.clients);
            if (clientId) {
                setSelectedClient(clientId);
                if (area === 'estate') {
                    fetchEstateManualInput(clientId);
                }
            }
        }).catch(err => {
            setClients([]);
        });

        getAllProviders();

        const taxYearOpts = [];
        const currentYear = new Date().getFullYear();
        
        for (const year in manualInputFields.fulltax) {
            taxYearOpts.push({ key: +year, text: year.toString(), value: year.toString() });
        }
        setTaxYearOptions(taxYearOpts);
        setTaxYear((currentYear - 1).toString());

    // eslint-disable-next-line
    }, []);

    const fieldInput_Change = (name, type) => {
        return (value) => {
            const auxQuestionnaire = { ...questionnaire };
            auxQuestionnaire[name] = { answer: value, type, whoAnswered: 'fpalpha' };
            setQuestionnaire(auxQuestionnaire);
        }
    }

    const fetchEstateManualInput = (clientId) => {
        setEstateManualInput(null);
        sysAPI.get(`/estate-manual-input/status?clientId=${clientId}`).then(response => {
            setEstateManualInput(response.data);
        }).catch(err => {
            console.error(err);
        });
    }

    const submitBtn_Click = (sendNotification) => {
        return async () => {
            const submittingQuestionnaire = { ...questionnaire };
            const allQuestions = fieldsSections.reduce((all, section) => [...all, ...section.fields], []);
            for (const question of allQuestions) {
                if (submittingQuestionnaire[question.name] === undefined) {
                    submittingQuestionnaire[question.name] = {
                        answer: question.type === 'number' ? '0' : 'undefined',
                        type: question.type,
                        whoAnswered: 'fpalpha',
                    };
                }
            }
            const indepth = fulltax_in_depth;
            for (const question in indepth) {
                submittingQuestionnaire[question] = {
                    answer: indepth[question],
                    type: indepth[question] === '0' ? 'number' : 'string',
                    whoAnswered: 'fpalpha',
                };
            }

            submittingQuestionnaire['clientIDWantsToInputTaxData'] = {
                answer: 'Yes',
                type: 'string',
                whoAnswered: 'fpalpha',
            }

            if (['fulltax'].includes(selectedAreaId)) {
                submittingQuestionnaire['clientIDTaxYear'] = {
                    answer: `${taxYear}`,
                    type: 'number',
                    whoAnswered: 'fpalpha',
                };
            }
            
            try {
                await sysAPI.post('/manual-input', {
                    clientId: selectedClient,
                    area: selectedAreaId,
                    sendNotification,
                    index: selectedAreaId === 'auto' || selectedAreaId === 'home' ? indexId : 1,
                    questionnaire: submittingQuestionnaire,
                    advisorId: advisor._id,
                    policyFilename: selectedAreaId === 'auto' || selectedAreaId === 'home' ? undefined : `clientIDFulltaxPolicy${taxYear}`,
                    year: ['fulltax'].includes(selectedAreaId) ? taxYear : undefined,
                });

                window.alert('Manual input saved correctly.');
                setAdvisor(null);
            }
            catch (err) {
                // console.log(err.response?.data?.message);
                window.alert(err.response?.data?.message || 'Could not submit manual input. Please try again later.');
            }
        }
    }

    const areasOptions = Object.keys(ALL_AREAS).map(areaId => ({ key: areaId, text: ALL_AREAS[areaId], value: areaId }));
    const clientsOptions = clients?.map(client => ({ key: client._id, text: `${client.clientIDFirstName} ${client.clientIDLastName} (${client.email})`, value: client._id })) || [];
    const fieldsSections = selectedAreaId === 'fulltax' ? manualInputFields[selectedAreaId]?.[taxYear] || [] : manualInputFields[selectedAreaId] || [];
    const clientInfo = clients?.find(client => client._id === selectedClient) || {};
    // const indexOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(ind => ({key: ind, text: ind, value: ind}));

    const taxYearDropdown_Change = (e) => {
        setTaxYear(e.target.textContent);
    }

    const areaDropdown_Change = (e, data) => {
        setSelectedAreaId(data.value);

        if (data.value === 'estate') {
            fetchEstateManualInput(clientInfo?._id);
        }
    }

    const clientDropdown_Change = (e, data) => {
        setSelectedClient(data.value);
        fetchEstateManualInput(data.value);
        dispatch(fetchCurrentClient(data.value));
    }

    const indexDropdown_Change = (e, data) => {
        setSelectedAsset(data.value);
        setIndexId(data.value);
    }

    useEffect(() => {
        if (selectedClient) getProperties(selectedClient);
    }, [selectedClient]);

    useEffect(() => {
        if (selectedAreaId === 'home' || selectedAreaId === 'auto') sortProperties(allAssets);
    }, [selectedAreaId]);

    return (<>
        <Modal open={advisor !== null} size='small'>
            <Modal.Header>Manual Input for {advisor?.fullName}</Modal.Header>
            <Modal.Content>
                <div style={{ display: 'flex' }}>
                    <Dropdown placeholder='Find client...' value={selectedClient} search selection options={clientsOptions} style={{ flex: '1 0 auto', marginRight: '10px' }} onChange={clientDropdown_Change} />
                    <Dropdown placeholder='Area...' value={selectedAreaId} selection options={areasOptions} style={{ maxWidth: '180px', minWidth: 'unset', flex: '1 1 auto', marginRight: '10px' }} onChange={areaDropdown_Change} />
                    {(selectedAreaId === 'auto' || selectedAreaId === 'home') &&
                        // <Dropdown placeholder='Index...' value={indexId} selection options={indexOptions} style={{ maxWidth: '100px', minWidth: 'unset', flex: '1 1 auto'}} onChange={indexDropdown_Change} />}
                        <Dropdown placeholder='Properties...' loading={fetchingProperties} value={selectedAsset} selection options={assets} style={{ maxWidth: '200px', minWidth: 'unset', flex: '1 1 auto'}} onChange={indexDropdown_Change} />}
                </div>
                {clients === null &&
                    <p style={{ margin: '0px', padding: '10px 0 0' }}>Loading clients, please wait...</p>
                }
                {(selectedAreaId === 'auto' || selectedAreaId === 'home') &&
                    <p style={{ textAlign: 'right', margin: '1em 0' }}>
                        <Button
                            color="yellow"
                            style={{ marginRight: 0 }}
                            onClick={() => setShowPropertiesModal(true)}
                        >
                            Properties
                        </Button>
                    </p>}
                <div style={{ marginTop: '1em', display: selectedClient?.length > 0 && selectedAreaId?.length > 0 ? 'block' : 'none' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center' }}>
                        <Header as='h3' style={{ margin: 0 }}>{ALL_AREAS[selectedAreaId]} for {clientInfo?.clientIDFirstName} {clientInfo?.clientIDLastName}</Header>
                        {['fulltax'].includes(selectedAreaId) &&
                            <div>
                                <span style={{ fontSize: '16px' }}>Tax Year</span>
                                <Dropdown selection options={taxYearOptions} value={taxYear} onChange={taxYearDropdown_Change} style={{ marginLeft: '10px' }} />
                            </div>
                        }
                    </div>
                    {fieldsSections?.map(section =>
                        <div>
                            <Header as='h3' style={{ margin: '15px 0 0' }}>
                                <Header.Content>{section.section}</Header.Content>
                            </Header>
                            {section.fields?.map(field =>
                                <ManualInputField {...field}
                                    section={section.section}
                                    value={questionnaire[field.name]?.answer || ''}
                                    onInputChange={fieldInput_Change(field.name, field.type)}
                                    providers={allProviders}
                                />
                            )}
                        </div>
                    )}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setAdvisor(null)}>Cancel</Button>
                <Button onClick={submitBtn_Click(false)}>Submit</Button>
                <Button onClick={submitBtn_Click(true)}>Submit and generate recommendations</Button>
            </Modal.Actions>
        </Modal>

        <PropertiesModal
            open={showPropertiesModal}
            closeBtn_Click={() => setShowPropertiesModal(false)}
            area={selectedAreaId}
            editingEnabled
            getAssets={getProperties}
            sortAssets={sortProperties}
        />
    </>)
}