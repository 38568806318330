// eslint-disable-next-line
export default [
    {
        fields: [
            {
                name: 'clientIDAutoInsuranceCompany',
                text: 'Insurance provider',
                type: 'string',
                options: 'provider',
            },
            {
                name: 'clientIDClientHasAutoInsurance',
                text: 'Auto Insurance?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDtotalPremiumAuto',
                text: 'Total annual premium:',
                type: 'number',
            },
            {
                name: 'clientIDbodilyInjuryPerPerson',
                text: 'Bodily injury per person covered:',
                type: 'number',
            },
            {
                name: 'clientIDbodilyInjuryPerOcurrence',
                text: 'Bodily injury per occurrence covered:',
                type: 'number',
            },
            {
                name: 'clientIDpropertyDamagePerOccurrence',
                text: 'Property damage per occurrence covered:',
                type: 'number',
            },
            {
                name: 'clientIDbasicPersonalInjuryProtection',
                text: 'Basic personal injury protection covered:',
                type: 'number',
            },
            {
                name: 'clientIDaddedPersonalInjuryProtection',
                text: 'Added personal injury protection covered:',
                type: 'number',
            },
            {
                name: 'clientIDsearchMedicalPaymentsPerPersonPerOccurrence',
                text: 'Medical payments per person coverage:',
                type: 'number',
            },
            {
                name: 'clientIDsearchOptionalBasicEconomicLossOBEL',
                text: 'Optional Basic Economic Loss (OBEL):',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDsupplementaryUninsured',
                text: 'Does the policy include Supplementary Uninsured/Underinsured Motorist Coverage (SUM)?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDcoverageOneCollision',
                text: 'Does the policy have collision insurance?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDPremiumForCollisionCoverage',
                text: 'Annual premium for collision coverage:',
                type: 'number',
            },
            {
                name: 'clientIDPartDdeductible',
                text: 'Collision deductible:',
                type: 'number',
            },
            {
                name: 'clientIDcoverageOtherThanCollision',
                text: 'Does the policy include comprehensive / other than collision coverage?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDPremiumForComprehensiveCoverage',
                text: 'Annual premium for comprehensive / other than collision coverage:',
                type: 'number',
            },
            {
                name: 'clientIDdeductibleOtherThanCollision',
                text: 'Comprehensive / other than collision deductible:',
                type: 'number',
            },
            {
                name: 'clientIDgapInsurance',
                text: 'Does the client have gap insurance?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDtowingLabor',
                text: 'Does the policy have towing and labor or emergency roadside assistance coverage?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDrentalCarReinbursment',
                text: 'Does the policy have rental car reimbursement coverage?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDglassCoverage',
                text: 'Does the policy have glass coverage?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDlossPayeeAuto',
                text: 'Does the policy include a loss payee?',
                type: 'string',
                options: ['Yes', 'No'],
            },
        ],
    },
]
