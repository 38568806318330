import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

export const DashboardMenu = ({ activeMenuItem }) => {
    const history = useHistory();
    const me = useSelector(state => state.me);

    const displayStyle = (allowedScope) => {
        const adminScope = allowedScope;
        return me?.scope?.split(' ').some(permission => adminScope.includes(permission))
            ? 'block'
            : 'none';
    }

    return (
        <Menu attached='top'>
            <Menu.Item
                active={activeMenuItem === 'Advisors'}
                name='Advisors'
                onClick={() => history.push('/dashboard/advisors')}
                style={{ display: displayStyle(['full_access', 'advisors']) }}
            />
            <Menu.Item
                active={activeMenuItem === 'Clients'}
                name='Clients'
                onClick={() => history.push('/dashboard/clients')}
                style={{ display: displayStyle(['full_access', 'clients']) }}
            />
            {/* //!En el displayStyle solo modificar los permisos a quienes si les mostrará */}
            <Menu.Item
                active={activeMenuItem === 'Companies'}
                name='Companies'
                onClick={() => history.push('/dashboard/companies')}
                style={{ display: displayStyle(['full_access', 'advisors']) }}
            />
            <Menu.Item
                active={activeMenuItem === 'Sales'}
                name='Sales'
                onClick={() => history.push('/dashboard/sales')}
                style={{ display: displayStyle(['full_access', 'sales']) }}
            />
            <Menu.Item
                active={activeMenuItem === 'Metrics'}
                name='Usage Metrics'
                onClick={() => history.push('/dashboard/metrics')}
                style={{ display: displayStyle(['full_access', 'metrics']) }}
            />
            <Menu.Item
                active={activeMenuItem === 'Manual Input'}
                name='Manual Input'
                onClick={() => history.push('/dashboard/manual-input')}
                style={{ display: displayStyle(['full_access', 'manual_input']) }}
            />
            <Menu.Item
                active={activeMenuItem === 'Resources'}
                name='Resources'
                onClick={() => history.push('/dashboard/resources')}
                style={{ display: displayStyle(['resources'])  }}
            />
            <Menu.Item
                active={activeMenuItem === 'Admin'}
                name='Admin'
                onClick={() => history.push('/dashboard/admin')}
                style={{ display: displayStyle(['admin']) }}
            />
            <Menu.Item
                active={activeMenuItem === 'Quickbooks'}
                name='Admin QuickBooks'
                onClick={() => history.push('/dashboard/quickbooks')}
                style={{ display: displayStyle(['admin']) }}
            />
            
        </Menu>
    )
}