// eslint-disable-next-line
export default [
    {
        section: 'Form 1040',
        fields: [
            {
                name: 'wages',
                text: 'Wages',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'taxExemptInterest',
                text: 'Tax-exempt interest',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'taxInterest',
                text: 'Taxable interest',
                line: 'Line 2b',
                type: 'number',
            },
            {
                name: 'qualifiedDividends',
                text: 'Qualified dividends',
                line: 'Line 3a',
                type: 'number',
            },
            {
                name: 'totalDividends',
                text: 'Ordinary dividends',
                line: 'Line 3b',
                type: 'number',
            },
            {
                name: 'iraDistribution',
                text: 'IRA distributions',
                line: 'Line 4a',
                type: 'number',
            },
            {
                name: 'taxableIRA',
                text: 'IRA distributions taxable amount',
                line: 'Line 4b',
                type: 'number',
            },
            {
                name: 'pension',
                text: 'Pensions and annuities',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'taxablePension',
                text: 'Pensions and annuities taxable amount',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'socialSecurityBenefits',
                text: 'Social security benefits',
                line: 'Line 6a',
                type: 'number',
            },
            {
                name: 'socialSecurity',
                text: 'Social security benefits taxable amount',
                line: 'Line 6b',
                type: 'number',
            },
            {
                name: 'otherIncome',
                text: 'Other Income',
                line: 'Line 8',
                type: 'number',
            },
            {
                name: 'capitalGainOrLoss',
                text: 'Capital gain or (loss)',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'totalIncome',
                text: 'Total income',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'adjustedIncome',
                text: 'Adjustments to income',
                line: 'Line 10c',
                type: 'number',
            },
            {
                name: 'adjustedGrossIncome',
                text: 'Adjusted gross income',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'standardDeductions',
                text: 'Standard deductions or itemized deductions',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'qbIncome',
                text: 'Qualified business income deductions',
                line: 'Line 13',
                type: 'number',
            },
            {
                name: 'sumDeductions',
                text: 'Sum Deductions',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'taxableIncome',
                text: 'Taxable income',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'taxes',
                text: 'Taxes',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'line17_1040',
                text: 'line 17',
                line: 'Line 17',
                type: 'number',
            },
            {
                name: 'line18_1040',
                text: 'line 18',
                line: 'Line 18',
                type: 'number',
            },
            {
                name: 'qualifyingDependentCreditClaimed',
                text: 'line 19',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'line20_1040',
                text: 'line 20',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'line21_1040',
                text: 'line 21',
                line: 'Line 21',
                type: 'number',
            },
            {
                name: 'line22_1040',
                text: 'line 22',
                line: 'Line 22',
                type: 'number',
            },
            {
                name: 'otherTaxesWithSelfEmployment',
                text: 'line 23',
                line: 'Line 23',
                type: 'number',
            },
            {
                name: 'totalTax',
                text: 'Total tax',
                line: 'Line 24',
                type: 'number',
            },
            {
                name: 'federalIncomeTaxWithheld',
                text: 'line 25d',
                line: 'Line 25d',
                type: 'number',
            },
            {
                name: 'estimatedTaxPaymentsAmountForLastYear',
                text: 'line 26',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'earnedIncomeCredit',
                text: 'line 27',
                line: 'Line 27',
                type: 'number',
            },
            {
                name: 'additionalChildTaxCredit',
                text: 'line 28',
                line: 'Line 28',
                type: 'number',
            },
            {
                name: 'americanOpportunityCreditTaken',
                text: 'line 29',
                line: 'Line 29',
                type: 'number',
            },
            {
                name: 'recoveryRebateCredit',
                text: 'line 30',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'amountfromSchedule3line15',
                text: 'line 31',
                line: 'Line 31',
                type: 'number',
            },
            {
                name: 'totalOtherPayments',
                text: 'line 32',
                line: 'Line 32',
                type: 'number',
            },
            {
                name: 'totalPayments',
                text: 'line 33',
                line: 'Line 33',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 1',
        fields: [
            {
                name: 'clientHasSchedule1',
                text: 'Has Schedule 1?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'alimonyReceived',
                text: 'Alimony received',
                line: 'Line 2a',
                type: 'number',
            },
            {
                name: 'businessIncome',
                text: 'Business income/loss',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'rentalRealEstate',
                text: 'Rental real estate, royalties, partnerships, S corporations, trusts, etc',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'healthSavings',
                text: 'Health savings',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'selfEmployedSEP',
                text: 'Self employed SEP',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'alimonyPaid',
                text: 'Alimony paid',
                line: 'Line 18a',
                type: 'number',
            },
            {
                name: 'iraDeduction',
                text: 'IRA deduction',
                line: 'Line 19',
                type: 'number',
            },
            {
                name: 'studentLoanInterestDeduction',
                text: 'Student loan interest deduction',
                line: 'Line 20',
                type: 'number',
            },
            {
                name: 'adjustmentToIncome',
                text: 'Adjustments to income',
                line: 'Line 22',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule 3',
        fields: [
            {
                name: 'clientHasSchedule3',
                text: 'Has Schedule 3?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'creditForChildCareTF',
                text: 'Credit for child/dependent care?',
                line: 'Line 2',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'creditForChildCare',
                text: 'Credit for child/dependent care',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'educationCredits',
                text: 'Education credits',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'energyCredits',
                text: 'Residential energy credits',
                line: 'Line 5',
                type: 'number',
            },
            {
                name: 'estimatedTax',
                text: 'Estimated tax',
                line: 'Line 8',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule A',
        fields: [
            {
                name: 'clientHasScheduleA',
                text: 'Has Schedule A?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'medicalExpenses',
                text: 'Medical and dental expenses',
                line: 'Line 1',
                type: 'number',
            },
            {
                name: 'medicalExpenses2',
                text: 'Multiply line 2 by 7.5%',
                line: 'Line 3',
                type: 'number',
            },
            {
                name: 'medicalExpenses3',
                text: 'Line 3 minus line 1',
                line: 'Line 4',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5a',
                text: 'Taxes you paid 5a',
                line: 'Line 5a',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5b',
                text: 'Taxes you paid 5b',
                line: 'Line 5b',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5c',
                text: 'Taxes you paid 5c',
                line: 'Line 5c',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5d',
                text: 'Taxes you paid 5d',
                line: 'Line 5d',
                type: 'number',
            },
            {
                name: 'taxesYouPaid5e',
                text: 'Taxes you paid 5e',
                line: 'Line 5e',
                type: 'number',
            },
            {
                name: 'taxesYouPaid',
                text: 'Deductions allowed for taxes paid',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'mortgageInterests',
                text: 'line 8a',
                line: 'Line 8a',
                type: 'number',
            },
            {
                name: 'mortgageInterestsNotReported',
                text: 'line 8b',
                line: 'Line 8b',
                type: 'number',
            },
            {
                name: 'pointsNotReported',
                text: 'line 8c',
                line: 'Line 8c',
                type: 'number',
            },
            {
                name: 'mortgageInsurancePremiums',
                text: 'line 8d',
                line: 'Line 8d',
                type: 'number',
            },
            {
                name: 'sumOfLines8',
                text: 'line 8e',
                line: 'Line 8e',
                type: 'number',
            },
            {
                name: 'investmentInterest',
                text: 'Investment interest',
                line: 'Line 9',
                type: 'number',
            },
            {
                name: 'interestYouPaid',
                text: 'Interest you paid (total)',
                line: 'Line 10',
                type: 'number',
            },
            {
                name: 'giftsByCash',
                text: 'Gifts by cash or check',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'giftsByLand',
                text: 'Other than by cash or check',
                line: 'Line 12',
                type: 'number',
            },
            {
                name: 'giftsToCharity',
                text: 'Gifts to charity',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'casualtyLosses',
                text: 'Casualty and theft losses',
                line: 'Line 15',
                type: 'number',
            },
            {
                name: 'otherItemizedDeductions',
                text: 'Other itemized deductions',
                line: 'Line 16',
                type: 'number',
            },
            {
                name: 'summationsItemizedDeductions',
                text: 'Total itemized deductions',
                line: 'Line 17',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule B Part 1',
        fields: [
            {
                name: 'clientHasScheduleB',
                text: 'Has Schedule B?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'scheduleb_1_0_text',
                text: 'scheduleb_1_0_text',
                line: 'Line 0 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_0_amount',
                text: 'scheduleb_1_0_amount',
                line: 'Line 0 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_1_text',
                text: 'scheduleb_1_1_text',
                line: 'Line 1 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_1_amount',
                text: 'scheduleb_1_1_amount',
                line: 'Line 1 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_2_text',
                text: 'scheduleb_1_2_text',
                line: 'Line 2 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_2_amount',
                text: 'scheduleb_1_2_amount',
                line: 'Line 2 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_3_text',
                text: 'scheduleb_1_3_text',
                line: 'Line 3 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_3_amount',
                text: 'scheduleb_1_3_amount',
                line: 'Line 3 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_4_text',
                text: 'scheduleb_1_4_text',
                line: 'Line 4 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_4_amount',
                text: 'scheduleb_1_4_amount',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_5_text',
                text: 'scheduleb_1_5_text',
                line: 'Line 5 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_5_amount',
                text: 'scheduleb_1_5_amount',
                line: 'Line 5 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_6_text',
                text: 'scheduleb_1_6_text',
                line: 'Line 6 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_6_amount',
                text: 'scheduleb_1_6_amount',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_7_text',
                text: 'scheduleb_1_7_text',
                line: 'Line 7 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_7_amount',
                text: 'scheduleb_1_7_amount',
                line: 'Line 7 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_8_text',
                text: 'scheduleb_1_8_text',
                line: 'Line 8 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_8_amount',
                text: 'scheduleb_1_8_amount',
                line: 'Line 8 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_9_text',
                text: 'scheduleb_1_9_text',
                line: 'Line 9 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_9_amount',
                text: 'scheduleb_1_9_amount',
                line: 'Line 9 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_10_text',
                text: 'scheduleb_1_10_text',
                line: 'Line 10 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_10_amount',
                text: 'scheduleb_1_10_amount',
                line: 'Line 10 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_11_text',
                text: 'scheduleb_1_11_text',
                line: 'Line 11 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_11_amount',
                text: 'scheduleb_1_11_amount',
                line: 'Line 11 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_12_text',
                text: 'scheduleb_1_12_text',
                line: 'Line 12 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_12_amount',
                text: 'scheduleb_1_12_amount',
                line: 'Line 12 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_13_text',
                text: 'scheduleb_1_13_text',
                line: 'Line 13 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_13_amount',
                text: 'scheduleb_1_13_amount',
                line: 'Line 13 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_14_text',
                text: 'scheduleb_1_14_text',
                line: 'Line 14 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_14_amount',
                text: 'scheduleb_1_14_amount',
                line: 'Line 14 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_1_15_text',
                text: 'scheduleb_1_15_text',
                line: 'Line 15 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_1_15_amount',
                text: 'scheduleb_1_15_amount',
                line: 'Line 15 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_2',
                text: 'scheduleb line 2',
                line: 'Line 2',
                type: 'number',
            },
            {
                name: 'scheduleb_4',
                text: 'scheduleb line 4',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule  B Part 2',
        fields: [
            // line 5
            {
                name: 'scheduleb_5_0_text',
                text: 'scheduleb_5_0_text',
                line: 'Line 0 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_0_amount',
                text: 'scheduleb_5_0_amount',
                line: 'Line 0 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_1_text',
                text: 'scheduleb_5_1_text',
                line: 'Line 1 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_1_amount',
                text: 'scheduleb_5_1_amount',
                line: 'Line 1 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_2_text',
                text: 'scheduleb_5_2_text',
                line: 'Line 2 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_2_amount',
                text: 'scheduleb_5_2_amount',
                line: 'Line 2 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_3_text',
                text: 'scheduleb_5_3_text',
                line: 'Line 3 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_3_amount',
                text: 'scheduleb_5_3_amount',
                line: 'Line 3 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_4_text',
                text: 'scheduleb_5_4_text',
                line: 'Line 4 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_4_amount',
                text: 'scheduleb_5_4_amount',
                line: 'Line 4 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_5_text',
                text: 'scheduleb_5_5_text',
                line: 'Line 5 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_5_amount',
                text: 'scheduleb_5_5_amount',
                line: 'Line 5 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_6_text',
                text: 'scheduleb_5_6_text',
                line: 'Line 6 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_6_amount',
                text: 'scheduleb_5_6_amount',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_7_text',
                text: 'scheduleb_5_7_text',
                line: 'Line 7 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_7_amount',
                text: 'scheduleb_5_7_amount',
                line: 'Line 7 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_8_text',
                text: 'scheduleb_5_8_text',
                line: 'Line 8 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_8_amount',
                text: 'scheduleb_5_8_amount',
                line: 'Line 8 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_9_text',
                text: 'scheduleb_5_9_text',
                line: 'Line 9 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_9_amount',
                text: 'scheduleb_5_9_amount',
                line: 'Line 9 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_10_text',
                text: 'scheduleb_5_10_text',
                line: 'Line 10 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_10_amount',
                text: 'scheduleb_5_10_amount',
                line: 'Line 10 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_11_text',
                text: 'scheduleb_5_11_text',
                line: 'Line 11 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_11_amount',
                text: 'scheduleb_5_11_amount',
                line: 'Line 11 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_12_text',
                text: 'scheduleb_5_12_text',
                line: 'Line 12 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_12_amount',
                text: 'scheduleb_5_12_amount',
                line: 'Line 12 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_13_text',
                text: 'scheduleb_5_13_text',
                line: 'Line 13 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_13_amount',
                text: 'scheduleb_5_13_amount',
                line: 'Line 13 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_14_text',
                text: 'scheduleb_5_14_text',
                line: 'Line 14 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_14_amount',
                text: 'scheduleb_5_14_amount',
                line: 'Line 14 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_5_15_text',
                text: 'scheduleb_5_15_text',
                line: 'Line 15 TEXT',
                type: 'string',
            },
            {
                name: 'scheduleb_5_15_amount',
                text: 'scheduleb_5_15_amount',
                line: 'Line 15 AMOUNT',
                type: 'number',
            },
            {
                name: 'scheduleb_6',
                text: 'scheduleb line 6',
                line: 'Line 6 AMOUNT',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule C',
        fields: [
            {
                name: 'clientHasScheduleC',
                text: 'Has Schedule C?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'contractLabor',
                text: 'Contract labor',
                line: 'Line 11',
                type: 'number',
            },
            {
                name: 'employeeBenefit',
                text: 'Employee benefit',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'wagesLessEmployeeCredits',
                text: 'Wages less employee credits',
                line: 'Line 26',
                type: 'number',
            },
            {
                name: 'expensesForHomeBusinessTF',
                text: 'Expenses for business use of your home?',
                line: 'Line 30',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'expensesForHomeBusiness',
                text: 'Expenses for business use of your home',
                line: 'Line 30',
                type: 'number',
            },
            {
                name: 'netProfitOrLoss',
                text: 'Net profit or (loss) from business',
                line: 'Line 31',
                type: 'number',
            },
        ],
    },
    {
        section: 'Schedule D',
        fields: [
            {
                name: 'clientHasScheduleD',
                text: 'Has Schedule D?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'shortTermLossCarryover',
                text: 'short Term Loss Carryover',
                line: 'Line 6',
                type: 'number',
            },
            {
                name: 'netShortTermCapitalGains',
                text: 'Net short-term capital gain or (loss)',
                line: 'Line 7',
                type: 'number',
            },
            {
                name: 'longTermLossCarryover',
                text: 'long Term Loss Carryover',
                line: 'Line 14',
                type: 'number',
            },
            {
                name: 'netLongTermCapitalGains',
                text: 'Net long-term capital gain (or loss)',
                line: 'Line 15',
                type: 'number',
            },
        ],
    },
    {
        section: 'Other Ones',
        fields: [
            {
                name: 'contributionMade457_1',
                text: 'Contribution made to plan 457',
                line: 'Form 8880 Line 2',
                type: 'number',
            },
            {
                name: 'contributionMade457_2',
                text: 'Spouse contribution made to plan 457',
                line: 'Form 8880 Line 2',
                type: 'number',
            },
            {
                name: 'clientHasScheduleSE',
                text: 'Has Schedule SE?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasScheduleE',
                text: 'Has Schedule E?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm2441',
                text: 'Has Form 2441?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm8995',
                text: 'Has Form 8995?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'hasForm8863',
                text: 'Has Form 8863?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm1120',
                text: 'Has Form 1120?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm1120S',
                text: 'Has Form 1120S?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm8839',
                text: 'Has Form 8839?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm6251',
                text: 'Has Form 6251?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientHasForm5695',
                text: 'Has Form 5695?',
                type: 'string',
                options: ['Yes', 'No'],
            },
        ],
    },
]