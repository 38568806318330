/* eslint-disable no-mixed-operators */
import { useEffect, useState } from "react"
//import { sysAPI } from "../apis/SysAPI";
import { DashboardTemplate } from "../templates/DashboardTemplate"
import { TabContainer } from "../components/shared/TabContainer"
import { paymentAPI } from "../apis/PaymentAPI";
import { /* Button, */ Header, /* Label */ } from "semantic-ui-react";
import quickbooks from '../data/img/C2QB_green_btn_med_default_2x.png'
import quickbooksHover from '../data/img/C2QB_green_btn_med_hover_2x.png'
import styles from "../css/quickbooks.module.css"

export const QuickbooksView = () => {
    const [isConnected, setIsConnected] = useState(false);
    useEffect(() => {
        paymentAPI.get('quickbooks/isConnected')
            .then(response => {
                console.log(response.data)
                setIsConnected(response.data.connected)
            })
    }, []);

    const loginButton = () => {
        paymentAPI.get('quickbooks/authUri')
            .then(response => {
                window.open(`${response.data.url}`, '_blank')
            })

    }

    const disconnect = () => {
        paymentAPI.post('quickbooks/disconnect')
            .then(response => {
                window.open(`/dashboard/quickbooks/disconnect`, '_blank')
                window.location.reload();
            })

    }

    const Hover = ({ onHover, children }) => (
        <div className={styles.hover}>
            <div className={styles.hover__nohover}>{children}</div>
            <div className={styles.hover__hover}>{onHover}</div>
        </div>
    )

    return (

        <DashboardTemplate scope={'admin'}>
            <TabContainer
                title='QuickBooks login'
                subtitle='quickbooks'
                headerOptions={<></>}
            >
                {isConnected ?
                    <div>
                        <div  style={{ marginTop: '20px' }} >
                            <Header as='h2' style={{ color: 'blue' }} >QuickBooks connected</Header>
                        </div>
                        <div style={{ marginTop: '5px',}}>
                            <i><small style={{'cursor': 'pointer', 'text-decoration': 'underline'}} onClick={() => disconnect()} >Disconnect from QuickBooks</small></i>
                        </div>
                    </div>
                    : <Hover onHover={<img src={quickbooksHover} style={{ marginTop: '20px', 'cursor': 'pointer' }} onClick={() => loginButton()} alt="..." />}>
                        <img src={quickbooks} style={{ marginTop: '20px', 'cursor': 'pointer' }} onClick={() => loginButton()} alt="..." />
                    </Hover>
                }
            </TabContainer>
        </DashboardTemplate>
    )
}
