import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Table, Header, Input, Label, Button } from 'semantic-ui-react';
import { fpalphaAPI } from "../../apis/FPAlphaAPI";
import { sysAPI } from "../../apis/SysAPI";
import { typeClient } from "../../helpers/advisors";
//import { ManualInputModal } from "../modal/ManualInputModal";

export const AdvisorClientsTab = ({ activeTab }) => {
    const clients = useSelector(state => state.currentAdvisorClients);
    const advisorProfile = useSelector(state => state.currentAdvisorProfile);

    const [searchInput, setSearchInput] = useState('');

    let renderClients = searchInput.length === 0
        ? clients
        : clients.filter(client =>
            client.clientIDFirstName.includes(searchInput)
            || client.clientIDLastName.includes(searchInput)
        );

    const downloadBtn_Click = (clientId, clientEmail) => {
        return () => {
            sysAPI.post('/generate-code').then(response => {
                fpalphaAPI.post('/tools/exportClient2JSON', {
                    clientsIds: [clientId],
                    download: true,
                    code: response.data.code,
                }, {
                    responseType: 'blob',
                }).then(response => {
                    const href = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    link.setAttribute('download', `${clientEmail}.fpclient`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            });
        }
    };

    return (
        <div style={{ display: activeTab === 'Clients' ? 'block' : 'none' }}>
            <Header as='h3'>
                <Header.Content>All Clients ({clients.length})</Header.Content>
            </Header>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Input
                    style={{ width: '300px' }}
                    icon='search' placeholder='Search...'
                    value={searchInput} onChange={e => setSearchInput(e.currentTarget.value)}
                />
            </div>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Full Name</Table.HeaderCell>
                        <Table.HeaderCell>E-mail</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Created on</Table.HeaderCell>
                        <Table.HeaderCell>Is Shared</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {renderClients.map((client, index) => 
                        <Table.Row key={index}>
                            <Table.Cell>
                                {client.clientIDFirstName} {client.clientIDLastName}
                            </Table.Cell>
                            <Table.Cell>{client.email}</Table.Cell>
                            <Table.Cell>
                                {typeClient(client)}
                                {/* ({client.salesforceContactId || client.redtailContactId || client.fromIntegration?.origin || 'Client'}) */}
                            </Table.Cell>
                            <Table.Cell>{moment(client.creationDate).format('MM/DD/YYYY')}</Table.Cell>
                            <Table.Cell>
                                {!client.isShared
                                    ? <Label color='grey'>Not Shared</Label>
                                    : client.idAdvisorList?.[0] === advisorProfile?._id
                                        ? <Label color='blue'>Shared (Owner)</Label>
                                        : <Label color='green'>Shared</Label>
                                }
                            </Table.Cell>
                            <Table.Cell>
                                <Button onClick={downloadBtn_Click(client._id, client.email)}>Download</Button>
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </div>
    )
}