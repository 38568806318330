import { Menu } from "semantic-ui-react"

const CompanyMenu = ({ activeTab, setActiveTab }) => {
    return (
        <Menu attached='top'>
            <Menu.Item active={activeTab === 'information'} onClick={() => setActiveTab('information')} name='Information' />
            <Menu.Item active={activeTab === 'advisors'} onClick={() => setActiveTab('advisors')} name='Advisors' />
            <Menu.Item active={activeTab === 'estate_snapshot'} onClick={() => setActiveTab('estate_snapshot')} name='Estate Snapshot' />
        </Menu>
    )
}

export default CompanyMenu