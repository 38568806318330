// eslint-disable-next-line
export default [
    {
        fields: [
            {
                name: 'clientIDHomeInsuranceCompany',
                text: 'Insurance provider',
                type: 'string',
                options: 'provider',
            },
            {
                name: 'clientIDClientHasHomeInsurance',
                text: 'Home Insurance?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDtotalPremiumHome',
                text: 'Total annual premium:',
                type: 'number',
            },
            {
                name: 'clientIDactualCashValue',
                text: 'Does the policy have actual cash value for dwelling?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDbasedeductible',
                text: 'Base deductible:',
                type: 'number',
            },
            {
                name: 'clientIDlargeLossDeductibleWaiver',
                text: 'Does the policy include a large loss deductible waiver?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDhouseOneEstimatedContentsValueFromDocs',
                text: 'Coverage amount for contents:',
                type: 'number',
            },
            {
                name: 'clientIDhouseOneDetachedStructuresCoverageAmountFromDocs',
                text: 'Detached structures coverage amount:',
                type: 'number',
            },
            {
                name: 'clientIDlookingForLossCoverageDueToFlood',
                text: 'Loss coverage due to flood:',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDlookingForEquipmentBreakdown',
                text: 'Equipment breakdown coverage:',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDoffPremiseTheftCoverageFromDocs',
                text: 'Does the policy include off-premises theft coverage?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDoffPremiseTheftCoverageAmountFromDocs',
                text: 'Off-premises theft coverage amount',
                type: 'number',
            },
            {
                name: 'clientIDdwellingInsuredValue',
                text: 'Dwelling insured value:',
                type: 'number',
            },
            {
                name: 'clientIDcashsettlementOptionFromDocs',
                text: 'Does the policy include a cash settlement option?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDrebuildingToCode',
                text: 'Does the policy include rebuilding to code?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDwaterAndSewerBackup',
                text: 'Does the policy have water and sewer back-up coverage?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDbasicOrBroadCoverage',
                text: 'Does the policy include basic dwelling coverage?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDwindDamage',
                text: 'Does the policy have wind damage coverage?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDlossOfUse',
                text: 'Is there loss of use protection on the policy?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDlossPayeeFromPolicy',
                text: 'Is there a mortgagee on the policy?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDumbrellaCoverage',
                text: 'Umbrella coverage:',
                type: 'number',
            },
            {
                name: 'clientIDpropertiesAutoLinkedToUmbrella',
                text: 'Are all properties auto-linked to umbrella?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDjewelryValueFromDocs',
                text: 'Jewelry value covered on policy:',
                type: 'number',
            },
            {
                name: 'clientIDsilverwareValueFromDocs',
                text: 'Silverware value covered on policy:',
                type: 'number',
            },
            {
                name: 'clientIDfineArtValueFromDocs',
                text: 'Fine art value covered on policy:',
                type: 'number',
            },
            {
                name: 'clientIDfursFromDocs',
                text: 'Furs value covered on policy:',
                type: 'number',
            },
            {
                name: 'clientIDwineValueFromDocs',
                text: 'Wine value covered on policy:',
                type: 'number',
            },
            {
                name: 'clientIDantiquesValueFromDocs',
                text: 'Antiques value covered on policy:',
                type: 'number',
            },
            {
                name: 'clientIDmusicalInstrumentsValueFromsDocs',
                text: 'Musical instruments value covered on policy:',
                type: 'number',
            },
            {
                name: 'clientIDvaluePhysicalCashFromDocs',
                text: 'Physical cash value covered on policy:',
                type: 'number',
            },
            {
                name: 'clientIDotherCollectiblesFromDocs',
                text: 'Other collectibles value covered on policy:',
                type: 'number',
            },
            {
                name: 'clientIDriderOrFloaterOnPolicy',
                text: 'Is there a rider or a floater on policy?',
                type: 'string',
                options: ['Yes', 'No'],
            },
            {
                name: 'clientIDlookingForEarthquakeCoverage',
                text: 'Does the policy include earthquake coverage?',
                type: 'string',
                options: ['Yes', 'No'],
            },
        ],
    },
]
