export const commifyNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isRealEstateAsset = (type) => {
    return ['primary_residence', 'investment_property', 'commercial_property', 'other_property', 'land'].includes(type) || type.startsWith('real_estate');
};

export const isRetirementAccountAsset = (type) => {
    return ['traditional_ira', '401k_plan', '457_plan', 'roth_ira'].includes(type);
};
