import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useLocation, matchPath, Redirect } from "react-router-dom";
import { DashboardMenu } from "../components/menu/DashboardMenu";
import { Header } from "../components/shared/Header"
import { Segment } from 'semantic-ui-react';

export const DashboardTemplate = ({ children, scope }) => {
    const [activeMenuItem, setActiveMenuItem] = useState('');
    const location = useLocation();
    const me = useSelector(state => state.me);

    useEffect(() => {
        const path = location.pathname;
        if (matchPath(path, { path: '/dashboard/advisors', exact: true }))
            setActiveMenuItem('Advisors');
        else if (matchPath(path, { path: '/dashboard/sales', exact: true }))
            setActiveMenuItem('Sales');
        else if (matchPath(path, { path: '/dashboard/advisors/:advisorId', exact: true }))
            setActiveMenuItem('');
        else if (matchPath(path, { path: '/dashboard/metrics', exact: true }))
            setActiveMenuItem('Metrics');
        else if (matchPath(path, {  path: '/dashboard/manual-input', exact: true }))
            setActiveMenuItem('Manual Input');
        else if (matchPath(path, {  path: '/dashboard/estate-manual-input/:clientId', exact: true }))
            setActiveMenuItem('');
        else if (matchPath(path, { path: '/dashboard/admin', exact: true })) {
            setActiveMenuItem('Admin');
        }
        else if (matchPath(path, { path: '/dashboard/quickbooks', exact: true })) {
            setActiveMenuItem('Quickbooks');
        }
        else if (matchPath(path, { path: '/dashboard/clients', exact: true })) {
            setActiveMenuItem('Clients');
        }
        else if (matchPath(path, { path: '/dashboard/resources', exact: true })) {
            setActiveMenuItem('Resources');
        }
        else if (matchPath(path, { path: '/dashboard/companies', exact: true })) {
            setActiveMenuItem('Companies');
        }

    // eslint-disable-next-line
    }, []);

    if (!me.scope) {
        return <div>Loading...</div>
    }

    if (scope === 'admin' && !me.scope.split(' ').some(s => s === 'admin')) {
        return <Redirect to='/dashboard?error=permission_denied' />
    }

    if (!me.scope.split(' ').some(s => ['full_access', ...scope.split(' ')].includes(s))) {
        return <Redirect to='/dashboard?error=permission_denied' />
    }

    return (
        <div className='dashboard-template'>
            <Header />
            <div style={{ padding: '0 20px 20px' }}>
                <DashboardMenu
                    activeMenuItem={activeMenuItem}
                />
                <Segment attached='bottom'>
                    {children}
                </Segment>
            </div>
        </div>
    )
}