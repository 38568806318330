import { sysAPI } from "../apis/SysAPI";

export const fetchCurrentCompany = (companyId) => {
    return async (dispatch) => {
        try {
            const {data} = await sysAPI.get(`/companies-v2/${companyId}`);
            //console.log({data})
            dispatch(setCurrentCompany(data.data));
        }
        catch (err) {
            console.error(err);
        }
    }
}

export const setCurrentCompany = (company) => {
    return {
        type: 'set_current_company',
        payload: company,
    };
}
