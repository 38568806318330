/* eslint-disable no-mixed-operators */
import { useSelector } from 'react-redux';
import { InputItem } from '../shared/EstateInputItem';
import { InsuranceCustomFields } from './InsuranceCustomFields';

export const HomeForm = ({ docId, insuranceProviders, assets }) => {
    const docType = `${docId}`;
    // const dispatch = useDispatch();
    // const client = useSelector(state => state.currentClient);
    const estateManualInput = useSelector(state => state.estateManualInput);

    const imagen = () => (
        <img
            style={{ marginLeft: '10px', maxHeight: '64px', height: '100%', width: 'auto', maxWidth:'200px' }}
            src={insuranceProviders.find(itm => itm.showName === estateManualInput?.[docType]?.insuranceProvider)?.url || ''}
            alt={estateManualInput?.[`${docType}___insuranceProvider`] || ''}
        />
    );

    // console.log('ASSETS:', assets);

    return (
        <>
            <div style={{ marginBottom: '20px' }}>
                <h4>Policy Summary</h4>
                <InputItem
                    docType={docType}
                    label='Select asset'
                    field='insuranceAsset'
                    nOptions={assets.filter(itm => itm.type === 'Home').map(itm => ({ key: itm._id, value: itm._id, text: itm.title }))}
                    version={[1,2,3]}
                    withoutPages
                />
                <InputItem
                    docType={docType}
                    label='Insurance provider'
                    field='insuranceProvider'
                    options={insuranceProviders.map(itm => (itm.showName))}
                    version={[1,2,3]}
                    addOptions
                    rightComponent={estateManualInput?.[docType]?.insuranceProvider && imagen}
                    withoutPages
                />
                <InputItem docType={docType} label='Name Insured' field='nameInsured' version={[1,2,3]} withoutPages />
                <InputItem docType={docType} label='Policy number' field='policyNumber' version={[1,2,3]} withoutPages />
                {/* <InputItem docType={docType} label='Policy period' field='policyPeriod' version={[1,2,3]} withoutPages dateFormat /> */}
                <div style={{ padding: '10px 0', marginBottom: '10px', borderBottom: '1px solid #e8e8e8', display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '8px'}}>
                    <div style={{width: '260px'}}>Policy period</div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1, columnGap: '8px'}}>
                        <InputItem docType={docType} label='' field='policyPeriodStart' version={[1,2,3]} withoutPages ignoreBorder dateFormat />
                        <InputItem docType={docType} label='' field='policyPeriodEnd' version={[1,2,3]} withoutPages ignoreBorder dateFormat />
                    </div>
                </div>
                <InputItem docType={docType} label='Mailing Address' field='mailingAddress' version={[1,2,3]} withoutPages />
                <InputItem docType={docType} label='Premium' field='premium' version={[1,2,3]} type={'number'} withoutPages />
                <div style={{ padding: '10px 0', marginBottom: '10px', borderBottom: '1px solid #e8e8e8', display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '8px'}}>
                    <div style={{width: '260px'}}>Property Deductible $</div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1, columnGap: '8px'}}>
                        <InputItem docType={docType} label='' field='deductible' version={[1,2,3]} type={'number'} withoutPages ignoreBorder />
                        <InputItem docType={docType} label='' field='deductibleSpecification' version={[1,2,3]} options={['Per Covered Loss', 'Non Applicable', 'Other']} addOptions withoutPages ignoreBorder />
                    </div>
                </div>
                <InputItem docType={docType} label='Property Location Address' field='propertyLocationAddress' version={[1,2,3]} withoutPages />
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Property</h4>
                <InsuranceCustomFields docId={docType} area="home" category="homeProperty" />
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Personal Liability</h4>
                <InsuranceCustomFields docId={docType} area="home" category="homePersonalLiability" />
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Personal Inland Marine Insurance</h4>
                <InsuranceCustomFields docId={docType} area="home" category="homePersonalInlandMarineInsurance" />
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Mortgagee/Lienholder(s)</h4>
                <InsuranceCustomFields docId={docType} area="home" category="homeMortgageeLienholder" />
            </div>

            <div style={{ marginBottom: '20px' }}>
                <h4>Other Coverages</h4>
                <InsuranceCustomFields docId={docType} area="home" category="homeOtherCoverages" />
            </div>
        </>
    )
}