import { Header, Grid, Segment, /* Card */ } from "semantic-ui-react";
import { useSelector } from "react-redux";
//import { useHistory } from "react-router-dom";

const CompanyInformationTab = ({ activeTab }) => {
  //const history = useHistory();
  const companyProfile = useSelector((state) => state.currentCompany);

  return (
    <div>
      {activeTab === "information" && (
        <div>
          <Grid columns={2}>
            <Grid.Column>
              <Header as="h3">General Information</Header>
              <Segment>
                Name:  {companyProfile?.name}
              </Segment>
              <Segment>
                POC:  {companyProfile?.poc?.fullName}
              </Segment>
              <Segment>
                Billing Advisor:  {companyProfile?.billingAdvisor?.fullName}
              </Segment>
            </Grid.Column>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default CompanyInformationTab;
