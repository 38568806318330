import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Message } from 'semantic-ui-react';
import qs from 'query-string';

export const Header = () => {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const { error } = qs.parse(window.location.search);
        if (error === 'permission_denied') {
            setErrorMessage({
                color: 'yellow',
                message: 'Permission denied. Please contact support for access.',
            });
        }
    }, []);

    return (
        <div>
            <div className='header' style={{ padding: '20px' }}>
                {errorMessage &&
                    <Message style={{ margin: '0px 0px 10px' }} onDismiss={() => setErrorMessage(null)} content={errorMessage.message} color={errorMessage.color} />
                }
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img width='160px' style={{ verticalAlign: 'top' }} src='https://storage.googleapis.com/fpalpha-production-public/fpalpha-assets/website/fpalpha-logo.svg' alt="..." />
                    <Button color='grey' onClick={() => history.push('/logout')}>Sign out</Button>
                </div>
            </div>
        </div>
    )
}