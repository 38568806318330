import { useState } from 'react';
import { Modal, Input, Segment, Button, Form, Container, Label, Select, Icon, Dimmer, Loader } from 'semantic-ui-react';
import { sysAPI } from '../../apis/SysAPI';

const AREAS = {
    'no_scope': 'Select a scope to add...',
    'admin': 'Admin',
    'full_access': 'Full access',
    'estate_manual_input': 'Estate manual input',
    'advisors': 'Advisors',
    'sales': 'Sales',
    'manual_input': 'Manual input',
    'metrics': 'Metrics',
    'resources': 'Resources',
    'insurance_manual_input': 'Insurance',
};

export const AddAdminModal = ({ modalState, setModalState }) => {
    const [showLoader, setShowLoader] = useState(false);
    const [enabledAreas, setEnabledAreas] = useState(['advisors', 'sales']);
    const [selectedAreaIdToAdd, setSelectedAreaIdToAdd] = useState('no_scope');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const removeEnabledArea_Click = (areaId) => {
        return () => {
            const tempEnabledAreas = [...enabledAreas];
            tempEnabledAreas.splice(tempEnabledAreas.indexOf(areaId), 1);
            setEnabledAreas(tempEnabledAreas);
        }
    }

    const addEnabledArea_Click = () => {
        if (selectedAreaIdToAdd === 'no_scope' || enabledAreas.indexOf(selectedAreaIdToAdd) >= 0) return;

        const tempEnabledAreas = [...enabledAreas];
        tempEnabledAreas.push(selectedAreaIdToAdd);
        setEnabledAreas(tempEnabledAreas);
    }

    const createAccountBtn_Click = async () => {
        const payload = {
            email,
            firstName, 
            lastName,
            scope: enabledAreas.join(' '),
        };
        console.log(payload);
        setShowLoader(true);

        try {
            console.log('Doing request');
            const createAdminResponse = await sysAPI.post('/users', payload);
            console.log(createAdminResponse);
            alert(`${firstName} ${lastName}'s account was created successfully. `);
            setModalState(false);
            setShowLoader(false);
        }
        catch (err) {
            console.error(err);
            alert(err.message);
            setShowLoader(false);
        }
    }

    const areasSelectOptions = Object.entries(AREAS).map(area => ({ key: area[0], value: area[0], text: area[1] }));

    return (
        <Modal size='tiny' open={modalState} onClose={() => setModalState(false)} onOpen={() => setModalState(true)}>
            <Modal.Header as='h3'>Create an Sys Admin Account</Modal.Header>
            <Modal.Content>
                <Segment>
                    <Form>
                        <Form.Field control={Input} fluid icon='user' iconPosition='left' placeholder='First Name' value={firstName} onChange={e => setFirstName(e.currentTarget.value)} />
                        <Form.Field control={Input} fluid icon='user' iconPosition='left' placeholder='Last Name' value={lastName} onChange={e => setLastName(e.currentTarget.value)} />
                        <Form.Field control={Input} fluid icon='at' iconPosition='left' placeholder='Email address' value={email} onChange={e => setEmail(e.currentTarget.value)}  />
                        <Form.Group widths='equal' inline>
                            <Form.Field fluid control={Select} options={areasSelectOptions} value={selectedAreaIdToAdd} onChange={(e, elem) => setSelectedAreaIdToAdd(elem.value)} />
                            <Button onClick={addEnabledArea_Click} color='blue'>Add</Button>
                        </Form.Group>
                        <Label.Group size='large' fluid>
                            {enabledAreas.map(areaId => <Label key={areaId}>{AREAS[areaId]}<Icon onClick={removeEnabledArea_Click(areaId)} name='delete' /></Label>)}
                        </Label.Group>
                    </Form>
                </Segment>
                <Container textAlign='right'>
                    <Button color='grey' onClick={() => setModalState(false)}>Cancel</Button>
                    <Button color='green' onClick={createAccountBtn_Click}>Create account</Button>
                </Container>
                <Dimmer active={showLoader}>
                    <Loader size='large' indeterminate>Creating advisor account...</Loader>
                </Dimmer>
            </Modal.Content>
        </Modal>
    )
}
