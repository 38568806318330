import { sysAPI } from "../apis/SysAPI";

export const setEstateManualInputField = (docType, field, value) => {
    return {
        type: 'set_estate_manual_input_field',
        payload: {
            docType,
            field,
            value,
        },
    }
};

export const removeEstateManualInputField = (docType, field) => {
    return {
        type: 'remove_estate_manual_input_field',
        payload: {
            docType,
            field,
        }
    }
};

export const addEstateManualInputAsset = (docType, id) => {
    return {
        type: 'add_estate_manual_input_asset',
        payload: {
            docType,
            id,
        }
    }
};

export const removeEstateManualInputAsset = (docType, assetId, fields, fieldsDocType) => {
    return (dispatch) => {
        dispatch({
            type: 'remove_estate_manual_input_asset',
            payload: {
                docType,
                id: assetId,
            }
        });

        for (const field of fields) {
            dispatch(removeEstateManualInputField(fieldsDocType || docType, `${field}___${assetId}`));
        }
    }
};

export const addEstateManualInputDocument = (docType, id) => {
    return {
        type: 'add_estate_manual_input_document',
        payload: {
            docType,
            id,
        },
    }
};

export const removeEstateManualInputDocument = (id) => {
    return {
        type: 'remove_estate_manual_input_document',
        payload: { id },
    }
};

export const setEstateManualInputChangeField = (docType, field, value) => {
    return {
        type: 'set_estate_manual_input_change_field',
        payload: {
            docType,
            field,
            value,
        },
    }
};

export const addEstateManualInputChangeDocument =  (docType, field, value) => {
    return {
        type: 'add_estate_manual_input_change_document',
        payload: {
            docType,
            field,
            value,
        },
    }
};

export const addEstateManualInputChangeAsset = (docType, id) => {
    return {
        type: 'add_estate_manual_input_asset',
        payload: {
            docType,
            id,
        }
    }
};

export const saveManualInputForLater = (clientId, index, area = 'estate') => {
    return async (dispatch, getState) => {
        const { estateManualInput, estateManualInputAssets, estateManualInputDocuments } = getState();

        const datapoints = {};

        for (const docType in estateManualInput) {
            for (const datapoint in estateManualInput[docType]) {
                datapoints[`${docType}___${datapoint}`] = estateManualInput[docType][datapoint];
            }
        }

        try {
            const response = await sysAPI.post(`/generic-manual-input`, {
                clientId,
                datapoints,
                assets: estateManualInputAssets,
                documents: estateManualInputDocuments,
                area,
                index,
            });
            console.log(response);
            window.alert('Information saved correctly.');
        }
        catch (err) {
            console.error(err);
            window.alert('ERROR: Something went wrong, please try again later.');
            return Promise.reject();
        }

        return Promise.resolve();
    }
};

export const fetchManualInputDataPoints = (clientId, index, area = 'estate') => {
    return async (dispatch) => {
        let datapoints = {};
        let assets = {};
        let documents = {};
        let changeLog = {};

        try {
            const response = await sysAPI.get(`/generic-manual-input?clientId=${clientId}&area=${area}&index=${index}`);
            datapoints = response.data.datapoints || {};
            assets = response.data.assets || {};
            documents = response.data.documents || {};
            changeLog = response.data.changeLog || {};
            console.log(response.data)
        }
        catch (err) {
            console.error();
        }
        
        dispatch(resetEstateManualInputFields());
        dispatch(resetEstateManualInputAssets());
        dispatch(resetEstateManualInputDocuments());

        dispatch(fetchEstateManualInputRequest(clientId, index, area));


        for (const datapoint in datapoints) {
            const [docType, _field, assetId] = datapoint.split('___');
            const field = !assetId ? _field : `${_field}___${assetId}`;

            dispatch(setEstateManualInputField(docType, `${field}`, datapoints[datapoint]));
        }

        for (const docType in assets) {
            for (const asset of assets[docType]) {
                dispatch(addEstateManualInputAsset(docType, asset.id));
            }
        }

        for (const docId in documents) {
            dispatch(addEstateManualInputDocument(documents[docId].type, docId));
        }

        if (Object.keys(changeLog).length > 0) {
            for (const datapoint in changeLog.datapoints || {}) {
                const [docType, _field, assetId] = datapoint.split('___');
                const field = !assetId ? _field : `${_field}___${assetId}`;

                dispatch(setEstateManualInputChangeField(docType, `${field}`, changeLog.saved?.datapoints[datapoint] || ''));
            }
        
            for (const docId in changeLog.documents || {}) {
                dispatch(addEstateManualInputChangeDocument(documents[docId].type, docId));
            }
        }
    }
};

export const finishReview = (clientId, index, area = 'estate') => {
    return async (dispatch) => {
        try {
            await dispatch(saveManualInputForLater(clientId, index, area));
            const response = await sysAPI.post(`/estate-manual-input/finish-review`, { clientId, index, area });
        }
        catch (err) {
            console.error(err);
            return Promise.reject();
        }

        return Promise.resolve();
    }
}

export const fetchEstateManualInputRequest = (clientId, index, area) => {
    const url = area === 'home' || area === 'auto'
        ? `/insurance-snapshot/status?clientId=${clientId}&area=${area}`
        : `/estate-manual-input/status?clientId=${clientId}&index=${index}`
    return async (dispatch) => {
        try {
            const response = await sysAPI.get(url);
            dispatch(setEstateManualInputRequest(response.data));
        }
        catch (err) {
            console.error(err);
        }
    }
}

export const setEstateManualInputRequest = (request) => {
    return {
        type: 'set_estate_manual_input_request',
        payload: request,
    }
}

export const resetEstateManualInputFields = () => {
    return {
        type: 'reset_estate_manual_input_fields',
        payload: null,
    }
}

export const resetEstateManualInputAssets = () => {
    return {
        type: 'reset_estate_manual_input_assets',
        payload: null,
    }
}

export const resetEstateManualInputDocuments = () => {
    return {
        type: 'reset_estate_manual_input_documents',
        payload: null,
    }
}